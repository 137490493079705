window.tinymce.Resource.add("tinymce.plugins.emoticons", {
  grinning: {
    keywords: ["face", "smile", "happy", "joy", ":D", "grin"],
    char: "\uD83D\uDE00",
    fitzpatrick_scale: false,
    category: "people"
  },
  grimacing: {
    keywords: ["face", "grimace", "teeth"],
    char: "\uD83D\uDE2C",
    fitzpatrick_scale: false,
    category: "people"
  },
  grin: {
    keywords: ["face", "happy", "smile", "joy", "kawaii"],
    char: "\uD83D\uDE01",
    fitzpatrick_scale: false,
    category: "people"
  },
  joy: {
    keywords: ["face", "cry", "tears", "weep", "happy", "happytears", "haha"],
    char: "\uD83D\uDE02",
    fitzpatrick_scale: false,
    category: "people"
  },
  rofl: {
    keywords: ["face", "rolling", "floor", "laughing", "lol", "haha"],
    char: "\uD83E\uDD23",
    fitzpatrick_scale: false,
    category: "people"
  },
  partying: {
    keywords: ["face", "celebration", "woohoo"],
    char: "\uD83E\uDD73",
    fitzpatrick_scale: false,
    category: "people"
  },
  smiley: {
    keywords: ["face", "happy", "joy", "haha", ":D", ":)", "smile", "funny"],
    char: "\uD83D\uDE03",
    fitzpatrick_scale: false,
    category: "people"
  },
  smile: {
    keywords: ["face", "happy", "joy", "funny", "haha", "laugh", "like", ":D", ":)"],
    char: "\uD83D\uDE04",
    fitzpatrick_scale: false,
    category: "people"
  },
  sweat_smile: {
    keywords: ["face", "hot", "happy", "laugh", "sweat", "smile", "relief"],
    char: "\uD83D\uDE05",
    fitzpatrick_scale: false,
    category: "people"
  },
  laughing: {
    keywords: ["happy", "joy", "lol", "satisfied", "haha", "face", "glad", "XD", "laugh"],
    char: "\uD83D\uDE06",
    fitzpatrick_scale: false,
    category: "people"
  },
  innocent: {
    keywords: ["face", "angel", "heaven", "halo"],
    char: "\uD83D\uDE07",
    fitzpatrick_scale: false,
    category: "people"
  },
  wink: {
    keywords: ["face", "happy", "mischievous", "secret", ";)", "smile", "eye"],
    char: "\uD83D\uDE09",
    fitzpatrick_scale: false,
    category: "people"
  },
  blush: {
    keywords: ["face", "smile", "happy", "flushed", "crush", "embarrassed", "shy", "joy"],
    char: "\uD83D\uDE0A",
    fitzpatrick_scale: false,
    category: "people"
  },
  slightly_smiling_face: {
    keywords: ["face", "smile"],
    char: "\uD83D\uDE42",
    fitzpatrick_scale: false,
    category: "people"
  },
  upside_down_face: {
    keywords: ["face", "flipped", "silly", "smile"],
    char: "\uD83D\uDE43",
    fitzpatrick_scale: false,
    category: "people"
  },
  relaxed: {
    keywords: ["face", "blush", "massage", "happiness"],
    char: "\u263A\uFE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  yum: {
    keywords: ["happy", "joy", "tongue", "smile", "face", "silly", "yummy", "nom", "delicious", "savouring"],
    char: "\uD83D\uDE0B",
    fitzpatrick_scale: false,
    category: "people"
  },
  relieved: {
    keywords: ["face", "relaxed", "phew", "massage", "happiness"],
    char: "\uD83D\uDE0C",
    fitzpatrick_scale: false,
    category: "people"
  },
  heart_eyes: {
    keywords: ["face", "love", "like", "affection", "valentines", "infatuation", "crush", "heart"],
    char: "\uD83D\uDE0D",
    fitzpatrick_scale: false,
    category: "people"
  },
  smiling_face_with_three_hearts: {
    keywords: ["face", "love", "like", "affection", "valentines", "infatuation", "crush", "hearts", "adore"],
    char: "\uD83E\uDD70",
    fitzpatrick_scale: false,
    category: "people"
  },
  kissing_heart: {
    keywords: ["face", "love", "like", "affection", "valentines", "infatuation", "kiss"],
    char: "\uD83D\uDE18",
    fitzpatrick_scale: false,
    category: "people"
  },
  kissing: {
    keywords: ["love", "like", "face", "3", "valentines", "infatuation", "kiss"],
    char: "\uD83D\uDE17",
    fitzpatrick_scale: false,
    category: "people"
  },
  kissing_smiling_eyes: {
    keywords: ["face", "affection", "valentines", "infatuation", "kiss"],
    char: "\uD83D\uDE19",
    fitzpatrick_scale: false,
    category: "people"
  },
  kissing_closed_eyes: {
    keywords: ["face", "love", "like", "affection", "valentines", "infatuation", "kiss"],
    char: "\uD83D\uDE1A",
    fitzpatrick_scale: false,
    category: "people"
  },
  stuck_out_tongue_winking_eye: {
    keywords: ["face", "prank", "childish", "playful", "mischievous", "smile", "wink", "tongue"],
    char: "\uD83D\uDE1C",
    fitzpatrick_scale: false,
    category: "people"
  },
  zany: {
    keywords: ["face", "goofy", "crazy"],
    char: "\uD83E\uDD2A",
    fitzpatrick_scale: false,
    category: "people"
  },
  raised_eyebrow: {
    keywords: ["face", "distrust", "scepticism", "disapproval", "disbelief", "surprise"],
    char: "\uD83E\uDD28",
    fitzpatrick_scale: false,
    category: "people"
  },
  monocle: {
    keywords: ["face", "stuffy", "wealthy"],
    char: "\uD83E\uDDD0",
    fitzpatrick_scale: false,
    category: "people"
  },
  stuck_out_tongue_closed_eyes: {
    keywords: ["face", "prank", "playful", "mischievous", "smile", "tongue"],
    char: "\uD83D\uDE1D",
    fitzpatrick_scale: false,
    category: "people"
  },
  stuck_out_tongue: {
    keywords: ["face", "prank", "childish", "playful", "mischievous", "smile", "tongue"],
    char: "\uD83D\uDE1B",
    fitzpatrick_scale: false,
    category: "people"
  },
  money_mouth_face: {
    keywords: ["face", "rich", "dollar", "money"],
    char: "\uD83E\uDD11",
    fitzpatrick_scale: false,
    category: "people"
  },
  nerd_face: {
    keywords: ["face", "nerdy", "geek", "dork"],
    char: "\uD83E\uDD13",
    fitzpatrick_scale: false,
    category: "people"
  },
  sunglasses: {
    keywords: ["face", "cool", "smile", "summer", "beach", "sunglass"],
    char: "\uD83D\uDE0E",
    fitzpatrick_scale: false,
    category: "people"
  },
  star_struck: {
    keywords: ["face", "smile", "starry", "eyes", "grinning"],
    char: "\uD83E\uDD29",
    fitzpatrick_scale: false,
    category: "people"
  },
  clown_face: {
    keywords: ["face"],
    char: "\uD83E\uDD21",
    fitzpatrick_scale: false,
    category: "people"
  },
  cowboy_hat_face: {
    keywords: ["face", "cowgirl", "hat"],
    char: "\uD83E\uDD20",
    fitzpatrick_scale: false,
    category: "people"
  },
  hugs: {
    keywords: ["face", "smile", "hug"],
    char: "\uD83E\uDD17",
    fitzpatrick_scale: false,
    category: "people"
  },
  smirk: {
    keywords: ["face", "smile", "mean", "prank", "smug", "sarcasm"],
    char: "\uD83D\uDE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  no_mouth: {
    keywords: ["face", "hellokitty"],
    char: "\uD83D\uDE36",
    fitzpatrick_scale: false,
    category: "people"
  },
  neutral_face: {
    keywords: ["indifference", "meh", ":|", "neutral"],
    char: "\uD83D\uDE10",
    fitzpatrick_scale: false,
    category: "people"
  },
  expressionless: {
    keywords: ["face", "indifferent", "-_-", "meh", "deadpan"],
    char: "\uD83D\uDE11",
    fitzpatrick_scale: false,
    category: "people"
  },
  unamused: {
    keywords: ["indifference", "bored", "straight face", "serious", "sarcasm", "unimpressed", "skeptical", "dubious", "side_eye"],
    char: "\uD83D\uDE12",
    fitzpatrick_scale: false,
    category: "people"
  },
  roll_eyes: {
    keywords: ["face", "eyeroll", "frustrated"],
    char: "\uD83D\uDE44",
    fitzpatrick_scale: false,
    category: "people"
  },
  thinking: {
    keywords: ["face", "hmmm", "think", "consider"],
    char: "\uD83E\uDD14",
    fitzpatrick_scale: false,
    category: "people"
  },
  lying_face: {
    keywords: ["face", "lie", "pinocchio"],
    char: "\uD83E\uDD25",
    fitzpatrick_scale: false,
    category: "people"
  },
  hand_over_mouth: {
    keywords: ["face", "whoops", "shock", "surprise"],
    char: "\uD83E\uDD2D",
    fitzpatrick_scale: false,
    category: "people"
  },
  shushing: {
    keywords: ["face", "quiet", "shhh"],
    char: "\uD83E\uDD2B",
    fitzpatrick_scale: false,
    category: "people"
  },
  symbols_over_mouth: {
    keywords: ["face", "swearing", "cursing", "cussing", "profanity", "expletive"],
    char: "\uD83E\uDD2C",
    fitzpatrick_scale: false,
    category: "people"
  },
  exploding_head: {
    keywords: ["face", "shocked", "mind", "blown"],
    char: "\uD83E\uDD2F",
    fitzpatrick_scale: false,
    category: "people"
  },
  flushed: {
    keywords: ["face", "blush", "shy", "flattered"],
    char: "\uD83D\uDE33",
    fitzpatrick_scale: false,
    category: "people"
  },
  disappointed: {
    keywords: ["face", "sad", "upset", "depressed", ":("],
    char: "\uD83D\uDE1E",
    fitzpatrick_scale: false,
    category: "people"
  },
  worried: {
    keywords: ["face", "concern", "nervous", ":("],
    char: "\uD83D\uDE1F",
    fitzpatrick_scale: false,
    category: "people"
  },
  angry: {
    keywords: ["mad", "face", "annoyed", "frustrated"],
    char: "\uD83D\uDE20",
    fitzpatrick_scale: false,
    category: "people"
  },
  rage: {
    keywords: ["angry", "mad", "hate", "despise"],
    char: "\uD83D\uDE21",
    fitzpatrick_scale: false,
    category: "people"
  },
  pensive: {
    keywords: ["face", "sad", "depressed", "upset"],
    char: "\uD83D\uDE14",
    fitzpatrick_scale: false,
    category: "people"
  },
  confused: {
    keywords: ["face", "indifference", "huh", "weird", "hmmm", ":/"],
    char: "\uD83D\uDE15",
    fitzpatrick_scale: false,
    category: "people"
  },
  slightly_frowning_face: {
    keywords: ["face", "frowning", "disappointed", "sad", "upset"],
    char: "\uD83D\uDE41",
    fitzpatrick_scale: false,
    category: "people"
  },
  frowning_face: {
    keywords: ["face", "sad", "upset", "frown"],
    char: "\u2639",
    fitzpatrick_scale: false,
    category: "people"
  },
  persevere: {
    keywords: ["face", "sick", "no", "upset", "oops"],
    char: "\uD83D\uDE23",
    fitzpatrick_scale: false,
    category: "people"
  },
  confounded: {
    keywords: ["face", "confused", "sick", "unwell", "oops", ":S"],
    char: "\uD83D\uDE16",
    fitzpatrick_scale: false,
    category: "people"
  },
  tired_face: {
    keywords: ["sick", "whine", "upset", "frustrated"],
    char: "\uD83D\uDE2B",
    fitzpatrick_scale: false,
    category: "people"
  },
  weary: {
    keywords: ["face", "tired", "sleepy", "sad", "frustrated", "upset"],
    char: "\uD83D\uDE29",
    fitzpatrick_scale: false,
    category: "people"
  },
  pleading: {
    keywords: ["face", "begging", "mercy"],
    char: "\uD83E\uDD7A",
    fitzpatrick_scale: false,
    category: "people"
  },
  triumph: {
    keywords: ["face", "gas", "phew", "proud", "pride"],
    char: "\uD83D\uDE24",
    fitzpatrick_scale: false,
    category: "people"
  },
  open_mouth: {
    keywords: ["face", "surprise", "impressed", "wow", "whoa", ":O"],
    char: "\uD83D\uDE2E",
    fitzpatrick_scale: false,
    category: "people"
  },
  scream: {
    keywords: ["face", "munch", "scared", "omg"],
    char: "\uD83D\uDE31",
    fitzpatrick_scale: false,
    category: "people"
  },
  fearful: {
    keywords: ["face", "scared", "terrified", "nervous", "oops", "huh"],
    char: "\uD83D\uDE28",
    fitzpatrick_scale: false,
    category: "people"
  },
  cold_sweat: {
    keywords: ["face", "nervous", "sweat"],
    char: "\uD83D\uDE30",
    fitzpatrick_scale: false,
    category: "people"
  },
  hushed: {
    keywords: ["face", "woo", "shh"],
    char: "\uD83D\uDE2F",
    fitzpatrick_scale: false,
    category: "people"
  },
  frowning: {
    keywords: ["face", "aw", "what"],
    char: "\uD83D\uDE26",
    fitzpatrick_scale: false,
    category: "people"
  },
  anguished: {
    keywords: ["face", "stunned", "nervous"],
    char: "\uD83D\uDE27",
    fitzpatrick_scale: false,
    category: "people"
  },
  cry: {
    keywords: ["face", "tears", "sad", "depressed", "upset", ":'("],
    char: "\uD83D\uDE22",
    fitzpatrick_scale: false,
    category: "people"
  },
  disappointed_relieved: {
    keywords: ["face", "phew", "sweat", "nervous"],
    char: "\uD83D\uDE25",
    fitzpatrick_scale: false,
    category: "people"
  },
  drooling_face: {
    keywords: ["face"],
    char: "\uD83E\uDD24",
    fitzpatrick_scale: false,
    category: "people"
  },
  sleepy: {
    keywords: ["face", "tired", "rest", "nap"],
    char: "\uD83D\uDE2A",
    fitzpatrick_scale: false,
    category: "people"
  },
  sweat: {
    keywords: ["face", "hot", "sad", "tired", "exercise"],
    char: "\uD83D\uDE13",
    fitzpatrick_scale: false,
    category: "people"
  },
  hot: {
    keywords: ["face", "feverish", "heat", "red", "sweating"],
    char: "\uD83E\uDD75",
    fitzpatrick_scale: false,
    category: "people"
  },
  cold: {
    keywords: ["face", "blue", "freezing", "frozen", "frostbite", "icicles"],
    char: "\uD83E\uDD76",
    fitzpatrick_scale: false,
    category: "people"
  },
  sob: {
    keywords: ["face", "cry", "tears", "sad", "upset", "depressed"],
    char: "\uD83D\uDE2D",
    fitzpatrick_scale: false,
    category: "people"
  },
  dizzy_face: {
    keywords: ["spent", "unconscious", "xox", "dizzy"],
    char: "\uD83D\uDE35",
    fitzpatrick_scale: false,
    category: "people"
  },
  astonished: {
    keywords: ["face", "xox", "surprised", "poisoned"],
    char: "\uD83D\uDE32",
    fitzpatrick_scale: false,
    category: "people"
  },
  zipper_mouth_face: {
    keywords: ["face", "sealed", "zipper", "secret"],
    char: "\uD83E\uDD10",
    fitzpatrick_scale: false,
    category: "people"
  },
  nauseated_face: {
    keywords: ["face", "vomit", "gross", "green", "sick", "throw up", "ill"],
    char: "\uD83E\uDD22",
    fitzpatrick_scale: false,
    category: "people"
  },
  sneezing_face: {
    keywords: ["face", "gesundheit", "sneeze", "sick", "allergy"],
    char: "\uD83E\uDD27",
    fitzpatrick_scale: false,
    category: "people"
  },
  vomiting: {
    keywords: ["face", "sick"],
    char: "\uD83E\uDD2E",
    fitzpatrick_scale: false,
    category: "people"
  },
  mask: {
    keywords: ["face", "sick", "ill", "disease"],
    char: "\uD83D\uDE37",
    fitzpatrick_scale: false,
    category: "people"
  },
  face_with_thermometer: {
    keywords: ["sick", "temperature", "thermometer", "cold", "fever"],
    char: "\uD83E\uDD12",
    fitzpatrick_scale: false,
    category: "people"
  },
  face_with_head_bandage: {
    keywords: ["injured", "clumsy", "bandage", "hurt"],
    char: "\uD83E\uDD15",
    fitzpatrick_scale: false,
    category: "people"
  },
  woozy: {
    keywords: ["face", "dizzy", "intoxicated", "tipsy", "wavy"],
    char: "\uD83E\uDD74",
    fitzpatrick_scale: false,
    category: "people"
  },
  sleeping: {
    keywords: ["face", "tired", "sleepy", "night", "zzz"],
    char: "\uD83D\uDE34",
    fitzpatrick_scale: false,
    category: "people"
  },
  zzz: {
    keywords: ["sleepy", "tired", "dream"],
    char: "\uD83D\uDCA4",
    fitzpatrick_scale: false,
    category: "people"
  },
  poop: {
    keywords: ["hankey", "shitface", "fail", "turd", "shit"],
    char: "\uD83D\uDCA9",
    fitzpatrick_scale: false,
    category: "people"
  },
  smiling_imp: {
    keywords: ["devil", "horns"],
    char: "\uD83D\uDE08",
    fitzpatrick_scale: false,
    category: "people"
  },
  imp: {
    keywords: ["devil", "angry", "horns"],
    char: "\uD83D\uDC7F",
    fitzpatrick_scale: false,
    category: "people"
  },
  japanese_ogre: {
    keywords: ["monster", "red", "mask", "halloween", "scary", "creepy", "devil", "demon", "japanese", "ogre"],
    char: "\uD83D\uDC79",
    fitzpatrick_scale: false,
    category: "people"
  },
  japanese_goblin: {
    keywords: ["red", "evil", "mask", "monster", "scary", "creepy", "japanese", "goblin"],
    char: "\uD83D\uDC7A",
    fitzpatrick_scale: false,
    category: "people"
  },
  skull: {
    keywords: ["dead", "skeleton", "creepy", "death"],
    char: "\uD83D\uDC80",
    fitzpatrick_scale: false,
    category: "people"
  },
  ghost: {
    keywords: ["halloween", "spooky", "scary"],
    char: "\uD83D\uDC7B",
    fitzpatrick_scale: false,
    category: "people"
  },
  alien: {
    keywords: ["UFO", "paul", "weird", "outer_space"],
    char: "\uD83D\uDC7D",
    fitzpatrick_scale: false,
    category: "people"
  },
  robot: {
    keywords: ["computer", "machine", "bot"],
    char: "\uD83E\uDD16",
    fitzpatrick_scale: false,
    category: "people"
  },
  smiley_cat: {
    keywords: ["animal", "cats", "happy", "smile"],
    char: "\uD83D\uDE3A",
    fitzpatrick_scale: false,
    category: "people"
  },
  smile_cat: {
    keywords: ["animal", "cats", "smile"],
    char: "\uD83D\uDE38",
    fitzpatrick_scale: false,
    category: "people"
  },
  joy_cat: {
    keywords: ["animal", "cats", "haha", "happy", "tears"],
    char: "\uD83D\uDE39",
    fitzpatrick_scale: false,
    category: "people"
  },
  heart_eyes_cat: {
    keywords: ["animal", "love", "like", "affection", "cats", "valentines", "heart"],
    char: "\uD83D\uDE3B",
    fitzpatrick_scale: false,
    category: "people"
  },
  smirk_cat: {
    keywords: ["animal", "cats", "smirk"],
    char: "\uD83D\uDE3C",
    fitzpatrick_scale: false,
    category: "people"
  },
  kissing_cat: {
    keywords: ["animal", "cats", "kiss"],
    char: "\uD83D\uDE3D",
    fitzpatrick_scale: false,
    category: "people"
  },
  scream_cat: {
    keywords: ["animal", "cats", "munch", "scared", "scream"],
    char: "\uD83D\uDE40",
    fitzpatrick_scale: false,
    category: "people"
  },
  crying_cat_face: {
    keywords: ["animal", "tears", "weep", "sad", "cats", "upset", "cry"],
    char: "\uD83D\uDE3F",
    fitzpatrick_scale: false,
    category: "people"
  },
  pouting_cat: {
    keywords: ["animal", "cats"],
    char: "\uD83D\uDE3E",
    fitzpatrick_scale: false,
    category: "people"
  },
  palms_up: {
    keywords: ["hands", "gesture", "cupped", "prayer"],
    char: "\uD83E\uDD32",
    fitzpatrick_scale: true,
    category: "people"
  },
  raised_hands: {
    keywords: ["gesture", "hooray", "yea", "celebration", "hands"],
    char: "\uD83D\uDE4C",
    fitzpatrick_scale: true,
    category: "people"
  },
  clap: {
    keywords: ["hands", "praise", "applause", "congrats", "yay"],
    char: "\uD83D\uDC4F",
    fitzpatrick_scale: true,
    category: "people"
  },
  wave: {
    keywords: ["hands", "gesture", "goodbye", "solong", "farewell", "hello", "hi", "palm"],
    char: "\uD83D\uDC4B",
    fitzpatrick_scale: true,
    category: "people"
  },
  call_me_hand: {
    keywords: ["hands", "gesture"],
    char: "\uD83E\uDD19",
    fitzpatrick_scale: true,
    category: "people"
  },
  "+1": {
    keywords: ["thumbsup", "yes", "awesome", "good", "agree", "accept", "cool", "hand", "like"],
    char: "\uD83D\uDC4D",
    fitzpatrick_scale: true,
    category: "people"
  },
  "-1": {
    keywords: ["thumbsdown", "no", "dislike", "hand"],
    char: "\uD83D\uDC4E",
    fitzpatrick_scale: true,
    category: "people"
  },
  facepunch: {
    keywords: ["angry", "violence", "fist", "hit", "attack", "hand"],
    char: "\uD83D\uDC4A",
    fitzpatrick_scale: true,
    category: "people"
  },
  fist: {
    keywords: ["fingers", "hand", "grasp"],
    char: "\u270A",
    fitzpatrick_scale: true,
    category: "people"
  },
  fist_left: {
    keywords: ["hand", "fistbump"],
    char: "\uD83E\uDD1B",
    fitzpatrick_scale: true,
    category: "people"
  },
  fist_right: {
    keywords: ["hand", "fistbump"],
    char: "\uD83E\uDD1C",
    fitzpatrick_scale: true,
    category: "people"
  },
  v: {
    keywords: ["fingers", "ohyeah", "hand", "peace", "victory", "two"],
    char: "\u270C",
    fitzpatrick_scale: true,
    category: "people"
  },
  ok_hand: {
    keywords: ["fingers", "limbs", "perfect", "ok", "okay"],
    char: "\uD83D\uDC4C",
    fitzpatrick_scale: true,
    category: "people"
  },
  raised_hand: {
    keywords: ["fingers", "stop", "highfive", "palm", "ban"],
    char: "\u270B",
    fitzpatrick_scale: true,
    category: "people"
  },
  raised_back_of_hand: {
    keywords: ["fingers", "raised", "backhand"],
    char: "\uD83E\uDD1A",
    fitzpatrick_scale: true,
    category: "people"
  },
  open_hands: {
    keywords: ["fingers", "butterfly", "hands", "open"],
    char: "\uD83D\uDC50",
    fitzpatrick_scale: true,
    category: "people"
  },
  muscle: {
    keywords: ["arm", "flex", "hand", "summer", "strong", "biceps"],
    char: "\uD83D\uDCAA",
    fitzpatrick_scale: true,
    category: "people"
  },
  pray: {
    keywords: ["please", "hope", "wish", "namaste", "highfive"],
    char: "\uD83D\uDE4F",
    fitzpatrick_scale: true,
    category: "people"
  },
  foot: {
    keywords: ["kick", "stomp"],
    char: "\uD83E\uDDB6",
    fitzpatrick_scale: true,
    category: "people"
  },
  leg: {
    keywords: ["kick", "limb"],
    char: "\uD83E\uDDB5",
    fitzpatrick_scale: true,
    category: "people"
  },
  handshake: {
    keywords: ["agreement", "shake"],
    char: "\uD83E\uDD1D",
    fitzpatrick_scale: false,
    category: "people"
  },
  point_up: {
    keywords: ["hand", "fingers", "direction", "up"],
    char: "\u261D",
    fitzpatrick_scale: true,
    category: "people"
  },
  point_up_2: {
    keywords: ["fingers", "hand", "direction", "up"],
    char: "\uD83D\uDC46",
    fitzpatrick_scale: true,
    category: "people"
  },
  point_down: {
    keywords: ["fingers", "hand", "direction", "down"],
    char: "\uD83D\uDC47",
    fitzpatrick_scale: true,
    category: "people"
  },
  point_left: {
    keywords: ["direction", "fingers", "hand", "left"],
    char: "\uD83D\uDC48",
    fitzpatrick_scale: true,
    category: "people"
  },
  point_right: {
    keywords: ["fingers", "hand", "direction", "right"],
    char: "\uD83D\uDC49",
    fitzpatrick_scale: true,
    category: "people"
  },
  fu: {
    keywords: ["hand", "fingers", "rude", "middle", "flipping"],
    char: "\uD83D\uDD95",
    fitzpatrick_scale: true,
    category: "people"
  },
  raised_hand_with_fingers_splayed: {
    keywords: ["hand", "fingers", "palm"],
    char: "\uD83D\uDD90",
    fitzpatrick_scale: true,
    category: "people"
  },
  love_you: {
    keywords: ["hand", "fingers", "gesture"],
    char: "\uD83E\uDD1F",
    fitzpatrick_scale: true,
    category: "people"
  },
  metal: {
    keywords: ["hand", "fingers", "evil_eye", "sign_of_horns", "rock_on"],
    char: "\uD83E\uDD18",
    fitzpatrick_scale: true,
    category: "people"
  },
  crossed_fingers: {
    keywords: ["good", "lucky"],
    char: "\uD83E\uDD1E",
    fitzpatrick_scale: true,
    category: "people"
  },
  vulcan_salute: {
    keywords: ["hand", "fingers", "spock", "star trek"],
    char: "\uD83D\uDD96",
    fitzpatrick_scale: true,
    category: "people"
  },
  writing_hand: {
    keywords: ["lower_left_ballpoint_pen", "stationery", "write", "compose"],
    char: "\u270D",
    fitzpatrick_scale: true,
    category: "people"
  },
  selfie: {
    keywords: ["camera", "phone"],
    char: "\uD83E\uDD33",
    fitzpatrick_scale: true,
    category: "people"
  },
  nail_care: {
    keywords: ["beauty", "manicure", "finger", "fashion", "nail"],
    char: "\uD83D\uDC85",
    fitzpatrick_scale: true,
    category: "people"
  },
  lips: {
    keywords: ["mouth", "kiss"],
    char: "\uD83D\uDC44",
    fitzpatrick_scale: false,
    category: "people"
  },
  tooth: {
    keywords: ["teeth", "dentist"],
    char: "\uD83E\uDDB7",
    fitzpatrick_scale: false,
    category: "people"
  },
  tongue: {
    keywords: ["mouth", "playful"],
    char: "\uD83D\uDC45",
    fitzpatrick_scale: false,
    category: "people"
  },
  ear: {
    keywords: ["face", "hear", "sound", "listen"],
    char: "\uD83D\uDC42",
    fitzpatrick_scale: true,
    category: "people"
  },
  nose: {
    keywords: ["smell", "sniff"],
    char: "\uD83D\uDC43",
    fitzpatrick_scale: true,
    category: "people"
  },
  eye: {
    keywords: ["face", "look", "see", "watch", "stare"],
    char: "\uD83D\uDC41",
    fitzpatrick_scale: false,
    category: "people"
  },
  eyes: {
    keywords: ["look", "watch", "stalk", "peek", "see"],
    char: "\uD83D\uDC40",
    fitzpatrick_scale: false,
    category: "people"
  },
  brain: {
    keywords: ["smart", "intelligent"],
    char: "\uD83E\uDDE0",
    fitzpatrick_scale: false,
    category: "people"
  },
  bust_in_silhouette: {
    keywords: ["user", "person", "human"],
    char: "\uD83D\uDC64",
    fitzpatrick_scale: false,
    category: "people"
  },
  busts_in_silhouette: {
    keywords: ["user", "person", "human", "group", "team"],
    char: "\uD83D\uDC65",
    fitzpatrick_scale: false,
    category: "people"
  },
  speaking_head: {
    keywords: ["user", "person", "human", "sing", "say", "talk"],
    char: "\uD83D\uDDE3",
    fitzpatrick_scale: false,
    category: "people"
  },
  baby: {
    keywords: ["child", "boy", "girl", "toddler"],
    char: "\uD83D\uDC76",
    fitzpatrick_scale: true,
    category: "people"
  },
  child: {
    keywords: ["gender-neutral", "young"],
    char: "\uD83E\uDDD2",
    fitzpatrick_scale: true,
    category: "people"
  },
  boy: {
    keywords: ["man", "male", "guy", "teenager"],
    char: "\uD83D\uDC66",
    fitzpatrick_scale: true,
    category: "people"
  },
  girl: {
    keywords: ["female", "woman", "teenager"],
    char: "\uD83D\uDC67",
    fitzpatrick_scale: true,
    category: "people"
  },
  adult: {
    keywords: ["gender-neutral", "person"],
    char: "\uD83E\uDDD1",
    fitzpatrick_scale: true,
    category: "people"
  },
  man: {
    keywords: ["mustache", "father", "dad", "guy", "classy", "sir", "moustache"],
    char: "\uD83D\uDC68",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman: {
    keywords: ["female", "girls", "lady"],
    char: "\uD83D\uDC69",
    fitzpatrick_scale: true,
    category: "people"
  },
  blonde_woman: {
    keywords: ["woman", "female", "girl", "blonde", "person"],
    char: "\uD83D\uDC71\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  blonde_man: {
    keywords: ["man", "male", "boy", "blonde", "guy", "person"],
    char: "\uD83D\uDC71",
    fitzpatrick_scale: true,
    category: "people"
  },
  bearded_person: {
    keywords: ["person", "bewhiskered"],
    char: "\uD83E\uDDD4",
    fitzpatrick_scale: true,
    category: "people"
  },
  older_adult: {
    keywords: ["human", "elder", "senior", "gender-neutral"],
    char: "\uD83E\uDDD3",
    fitzpatrick_scale: true,
    category: "people"
  },
  older_man: {
    keywords: ["human", "male", "men", "old", "elder", "senior"],
    char: "\uD83D\uDC74",
    fitzpatrick_scale: true,
    category: "people"
  },
  older_woman: {
    keywords: ["human", "female", "women", "lady", "old", "elder", "senior"],
    char: "\uD83D\uDC75",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_with_gua_pi_mao: {
    keywords: ["male", "boy", "chinese"],
    char: "\uD83D\uDC72",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_with_headscarf: {
    keywords: ["female", "hijab", "mantilla", "tichel"],
    char: "\uD83E\uDDD5",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_with_turban: {
    keywords: ["female", "indian", "hinduism", "arabs", "woman"],
    char: "\uD83D\uDC73\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_with_turban: {
    keywords: ["male", "indian", "hinduism", "arabs"],
    char: "\uD83D\uDC73",
    fitzpatrick_scale: true,
    category: "people"
  },
  policewoman: {
    keywords: ["woman", "police", "law", "legal", "enforcement", "arrest", "911", "female"],
    char: "\uD83D\uDC6E\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  policeman: {
    keywords: ["man", "police", "law", "legal", "enforcement", "arrest", "911"],
    char: "\uD83D\uDC6E",
    fitzpatrick_scale: true,
    category: "people"
  },
  construction_worker_woman: {
    keywords: ["female", "human", "wip", "build", "construction", "worker", "labor", "woman"],
    char: "\uD83D\uDC77\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  construction_worker_man: {
    keywords: ["male", "human", "wip", "guy", "build", "construction", "worker", "labor"],
    char: "\uD83D\uDC77",
    fitzpatrick_scale: true,
    category: "people"
  },
  guardswoman: {
    keywords: ["uk", "gb", "british", "female", "royal", "woman"],
    char: "\uD83D\uDC82\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  guardsman: {
    keywords: ["uk", "gb", "british", "male", "guy", "royal"],
    char: "\uD83D\uDC82",
    fitzpatrick_scale: true,
    category: "people"
  },
  female_detective: {
    keywords: ["human", "spy", "detective", "female", "woman"],
    char: "\uD83D\uDD75\uFE0F\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  male_detective: {
    keywords: ["human", "spy", "detective"],
    char: "\uD83D\uDD75",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_health_worker: {
    keywords: ["doctor", "nurse", "therapist", "healthcare", "woman", "human"],
    char: "\uD83D\uDC69\u200D\u2695\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_health_worker: {
    keywords: ["doctor", "nurse", "therapist", "healthcare", "man", "human"],
    char: "\uD83D\uDC68\u200D\u2695\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_farmer: {
    keywords: ["rancher", "gardener", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDF3E",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_farmer: {
    keywords: ["rancher", "gardener", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDF3E",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_cook: {
    keywords: ["chef", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDF73",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_cook: {
    keywords: ["chef", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDF73",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_student: {
    keywords: ["graduate", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDF93",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_student: {
    keywords: ["graduate", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDF93",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_singer: {
    keywords: ["rockstar", "entertainer", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDFA4",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_singer: {
    keywords: ["rockstar", "entertainer", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDFA4",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_teacher: {
    keywords: ["instructor", "professor", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDFEB",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_teacher: {
    keywords: ["instructor", "professor", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDFEB",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_factory_worker: {
    keywords: ["assembly", "industrial", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDFED",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_factory_worker: {
    keywords: ["assembly", "industrial", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDFED",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_technologist: {
    keywords: ["coder", "developer", "engineer", "programmer", "software", "woman", "human", "laptop", "computer"],
    char: "\uD83D\uDC69\u200D\uD83D\uDCBB",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_technologist: {
    keywords: ["coder", "developer", "engineer", "programmer", "software", "man", "human", "laptop", "computer"],
    char: "\uD83D\uDC68\u200D\uD83D\uDCBB",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_office_worker: {
    keywords: ["business", "manager", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83D\uDCBC",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_office_worker: {
    keywords: ["business", "manager", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83D\uDCBC",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_mechanic: {
    keywords: ["plumber", "woman", "human", "wrench"],
    char: "\uD83D\uDC69\u200D\uD83D\uDD27",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_mechanic: {
    keywords: ["plumber", "man", "human", "wrench"],
    char: "\uD83D\uDC68\u200D\uD83D\uDD27",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_scientist: {
    keywords: ["biologist", "chemist", "engineer", "physicist", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83D\uDD2C",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_scientist: {
    keywords: ["biologist", "chemist", "engineer", "physicist", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83D\uDD2C",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_artist: {
    keywords: ["painter", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83C\uDFA8",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_artist: {
    keywords: ["painter", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83C\uDFA8",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_firefighter: {
    keywords: ["fireman", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83D\uDE92",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_firefighter: {
    keywords: ["fireman", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83D\uDE92",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_pilot: {
    keywords: ["aviator", "plane", "woman", "human"],
    char: "\uD83D\uDC69\u200D\u2708\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_pilot: {
    keywords: ["aviator", "plane", "man", "human"],
    char: "\uD83D\uDC68\u200D\u2708\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_astronaut: {
    keywords: ["space", "rocket", "woman", "human"],
    char: "\uD83D\uDC69\u200D\uD83D\uDE80",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_astronaut: {
    keywords: ["space", "rocket", "man", "human"],
    char: "\uD83D\uDC68\u200D\uD83D\uDE80",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_judge: {
    keywords: ["justice", "court", "woman", "human"],
    char: "\uD83D\uDC69\u200D\u2696\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_judge: {
    keywords: ["justice", "court", "man", "human"],
    char: "\uD83D\uDC68\u200D\u2696\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_superhero: {
    keywords: ["woman", "female", "good", "heroine", "superpowers"],
    char: "\uD83E\uDDB8\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_superhero: {
    keywords: ["man", "male", "good", "hero", "superpowers"],
    char: "\uD83E\uDDB8\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_supervillain: {
    keywords: ["woman", "female", "evil", "bad", "criminal", "heroine", "superpowers"],
    char: "\uD83E\uDDB9\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_supervillain: {
    keywords: ["man", "male", "evil", "bad", "criminal", "hero", "superpowers"],
    char: "\uD83E\uDDB9\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  mrs_claus: {
    keywords: ["woman", "female", "xmas", "mother christmas"],
    char: "\uD83E\uDD36",
    fitzpatrick_scale: true,
    category: "people"
  },
  santa: {
    keywords: ["festival", "man", "male", "xmas", "father christmas"],
    char: "\uD83C\uDF85",
    fitzpatrick_scale: true,
    category: "people"
  },
  sorceress: {
    keywords: ["woman", "female", "mage", "witch"],
    char: "\uD83E\uDDD9\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  wizard: {
    keywords: ["man", "male", "mage", "sorcerer"],
    char: "\uD83E\uDDD9\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_elf: {
    keywords: ["woman", "female"],
    char: "\uD83E\uDDDD\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_elf: {
    keywords: ["man", "male"],
    char: "\uD83E\uDDDD\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_vampire: {
    keywords: ["woman", "female"],
    char: "\uD83E\uDDDB\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_vampire: {
    keywords: ["man", "male", "dracula"],
    char: "\uD83E\uDDDB\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_zombie: {
    keywords: ["woman", "female", "undead", "walking dead"],
    char: "\uD83E\uDDDF\u200D\u2640\uFE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  man_zombie: {
    keywords: ["man", "male", "dracula", "undead", "walking dead"],
    char: "\uD83E\uDDDF\u200D\u2642\uFE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  woman_genie: {
    keywords: ["woman", "female"],
    char: "\uD83E\uDDDE\u200D\u2640\uFE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  man_genie: {
    keywords: ["man", "male"],
    char: "\uD83E\uDDDE\u200D\u2642\uFE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  mermaid: {
    keywords: ["woman", "female", "merwoman", "ariel"],
    char: "\uD83E\uDDDC\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  merman: {
    keywords: ["man", "male", "triton"],
    char: "\uD83E\uDDDC\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_fairy: {
    keywords: ["woman", "female"],
    char: "\uD83E\uDDDA\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_fairy: {
    keywords: ["man", "male"],
    char: "\uD83E\uDDDA\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  angel: {
    keywords: ["heaven", "wings", "halo"],
    char: "\uD83D\uDC7C",
    fitzpatrick_scale: true,
    category: "people"
  },
  pregnant_woman: {
    keywords: ["baby"],
    char: "\uD83E\uDD30",
    fitzpatrick_scale: true,
    category: "people"
  },
  breastfeeding: {
    keywords: ["nursing", "baby"],
    char: "\uD83E\uDD31",
    fitzpatrick_scale: true,
    category: "people"
  },
  princess: {
    keywords: ["girl", "woman", "female", "blond", "crown", "royal", "queen"],
    char: "\uD83D\uDC78",
    fitzpatrick_scale: true,
    category: "people"
  },
  prince: {
    keywords: ["boy", "man", "male", "crown", "royal", "king"],
    char: "\uD83E\uDD34",
    fitzpatrick_scale: true,
    category: "people"
  },
  bride_with_veil: {
    keywords: ["couple", "marriage", "wedding", "woman", "bride"],
    char: "\uD83D\uDC70",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_in_tuxedo: {
    keywords: ["couple", "marriage", "wedding", "groom"],
    char: "\uD83E\uDD35",
    fitzpatrick_scale: true,
    category: "people"
  },
  running_woman: {
    keywords: ["woman", "walking", "exercise", "race", "running", "female"],
    char: "\uD83C\uDFC3\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  running_man: {
    keywords: ["man", "walking", "exercise", "race", "running"],
    char: "\uD83C\uDFC3",
    fitzpatrick_scale: true,
    category: "people"
  },
  walking_woman: {
    keywords: ["human", "feet", "steps", "woman", "female"],
    char: "\uD83D\uDEB6\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  walking_man: {
    keywords: ["human", "feet", "steps"],
    char: "\uD83D\uDEB6",
    fitzpatrick_scale: true,
    category: "people"
  },
  dancer: {
    keywords: ["female", "girl", "woman", "fun"],
    char: "\uD83D\uDC83",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_dancing: {
    keywords: ["male", "boy", "fun", "dancer"],
    char: "\uD83D\uDD7A",
    fitzpatrick_scale: true,
    category: "people"
  },
  dancing_women: {
    keywords: ["female", "bunny", "women", "girls"],
    char: "\uD83D\uDC6F",
    fitzpatrick_scale: false,
    category: "people"
  },
  dancing_men: {
    keywords: ["male", "bunny", "men", "boys"],
    char: "\uD83D\uDC6F\u200D\u2642\uFE0F",
    fitzpatrick_scale: false,
    category: "people"
  },
  couple: {
    keywords: ["pair", "people", "human", "love", "date", "dating", "like", "affection", "valentines", "marriage"],
    char: "\uD83D\uDC6B",
    fitzpatrick_scale: false,
    category: "people"
  },
  two_men_holding_hands: {
    keywords: ["pair", "couple", "love", "like", "bromance", "friendship", "people", "human"],
    char: "\uD83D\uDC6C",
    fitzpatrick_scale: false,
    category: "people"
  },
  two_women_holding_hands: {
    keywords: ["pair", "friendship", "couple", "love", "like", "female", "people", "human"],
    char: "\uD83D\uDC6D",
    fitzpatrick_scale: false,
    category: "people"
  },
  bowing_woman: {
    keywords: ["woman", "female", "girl"],
    char: "\uD83D\uDE47\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  bowing_man: {
    keywords: ["man", "male", "boy"],
    char: "\uD83D\uDE47",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_facepalming: {
    keywords: ["man", "male", "boy", "disbelief"],
    char: "\uD83E\uDD26\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_facepalming: {
    keywords: ["woman", "female", "girl", "disbelief"],
    char: "\uD83E\uDD26\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_shrugging: {
    keywords: ["woman", "female", "girl", "confused", "indifferent", "doubt"],
    char: "\uD83E\uDD37",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_shrugging: {
    keywords: ["man", "male", "boy", "confused", "indifferent", "doubt"],
    char: "\uD83E\uDD37\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  tipping_hand_woman: {
    keywords: ["female", "girl", "woman", "human", "information"],
    char: "\uD83D\uDC81",
    fitzpatrick_scale: true,
    category: "people"
  },
  tipping_hand_man: {
    keywords: ["male", "boy", "man", "human", "information"],
    char: "\uD83D\uDC81\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  no_good_woman: {
    keywords: ["female", "girl", "woman", "nope"],
    char: "\uD83D\uDE45",
    fitzpatrick_scale: true,
    category: "people"
  },
  no_good_man: {
    keywords: ["male", "boy", "man", "nope"],
    char: "\uD83D\uDE45\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  ok_woman: {
    keywords: ["women", "girl", "female", "pink", "human", "woman"],
    char: "\uD83D\uDE46",
    fitzpatrick_scale: true,
    category: "people"
  },
  ok_man: {
    keywords: ["men", "boy", "male", "blue", "human", "man"],
    char: "\uD83D\uDE46\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  raising_hand_woman: {
    keywords: ["female", "girl", "woman"],
    char: "\uD83D\uDE4B",
    fitzpatrick_scale: true,
    category: "people"
  },
  raising_hand_man: {
    keywords: ["male", "boy", "man"],
    char: "\uD83D\uDE4B\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  pouting_woman: {
    keywords: ["female", "girl", "woman"],
    char: "\uD83D\uDE4E",
    fitzpatrick_scale: true,
    category: "people"
  },
  pouting_man: {
    keywords: ["male", "boy", "man"],
    char: "\uD83D\uDE4E\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  frowning_woman: {
    keywords: ["female", "girl", "woman", "sad", "depressed", "discouraged", "unhappy"],
    char: "\uD83D\uDE4D",
    fitzpatrick_scale: true,
    category: "people"
  },
  frowning_man: {
    keywords: ["male", "boy", "man", "sad", "depressed", "discouraged", "unhappy"],
    char: "\uD83D\uDE4D\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  haircut_woman: {
    keywords: ["female", "girl", "woman"],
    char: "\uD83D\uDC87",
    fitzpatrick_scale: true,
    category: "people"
  },
  haircut_man: {
    keywords: ["male", "boy", "man"],
    char: "\uD83D\uDC87\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  massage_woman: {
    keywords: ["female", "girl", "woman", "head"],
    char: "\uD83D\uDC86",
    fitzpatrick_scale: true,
    category: "people"
  },
  massage_man: {
    keywords: ["male", "boy", "man", "head"],
    char: "\uD83D\uDC86\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  woman_in_steamy_room: {
    keywords: ["female", "woman", "spa", "steamroom", "sauna"],
    char: "\uD83E\uDDD6\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  man_in_steamy_room: {
    keywords: ["male", "man", "spa", "steamroom", "sauna"],
    char: "\uD83E\uDDD6\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "people"
  },
  couple_with_heart_woman_man: {
    keywords: ["pair", "love", "like", "affection", "human", "dating", "valentines", "marriage"],
    char: "\uD83D\uDC91",
    fitzpatrick_scale: false,
    category: "people"
  },
  couple_with_heart_woman_woman: {
    keywords: ["pair", "love", "like", "affection", "human", "dating", "valentines", "marriage"],
    char: "\uD83D\uDC69\u200D\u2764\uFE0F\u200D\uD83D\uDC69",
    fitzpatrick_scale: false,
    category: "people"
  },
  couple_with_heart_man_man: {
    keywords: ["pair", "love", "like", "affection", "human", "dating", "valentines", "marriage"],
    char: "\uD83D\uDC68\u200D\u2764\uFE0F\u200D\uD83D\uDC68",
    fitzpatrick_scale: false,
    category: "people"
  },
  couplekiss_man_woman: {
    keywords: ["pair", "valentines", "love", "like", "dating", "marriage"],
    char: "\uD83D\uDC8F",
    fitzpatrick_scale: false,
    category: "people"
  },
  couplekiss_woman_woman: {
    keywords: ["pair", "valentines", "love", "like", "dating", "marriage"],
    char: "\uD83D\uDC69\u200D\u2764\uFE0F\u200D\uD83D\uDC8B\u200D\uD83D\uDC69",
    fitzpatrick_scale: false,
    category: "people"
  },
  couplekiss_man_man: {
    keywords: ["pair", "valentines", "love", "like", "dating", "marriage"],
    char: "\uD83D\uDC68\u200D\u2764\uFE0F\u200D\uD83D\uDC8B\u200D\uD83D\uDC68",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_woman_boy: {
    keywords: ["home", "parents", "child", "mom", "dad", "father", "mother", "people", "human"],
    char: "\uD83D\uDC6A",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_woman_girl: {
    keywords: ["home", "parents", "people", "human", "child"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_woman_girl_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_woman_boy_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC66\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_woman_girl_girl: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_woman_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_woman_girl: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_woman_girl_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_woman_boy_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC66\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_woman_girl_girl: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_man_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_man_girl: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_man_girl_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_man_boy_boy: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC66\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_man_girl_girl: {
    keywords: ["home", "parents", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_boy: {
    keywords: ["home", "parent", "people", "human", "child"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_girl: {
    keywords: ["home", "parent", "people", "human", "child"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_girl_boy: {
    keywords: ["home", "parent", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_boy_boy: {
    keywords: ["home", "parent", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC66\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_woman_girl_girl: {
    keywords: ["home", "parent", "people", "human", "children"],
    char: "\uD83D\uDC69\u200D\uD83D\uDC67\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_boy: {
    keywords: ["home", "parent", "people", "human", "child"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_girl: {
    keywords: ["home", "parent", "people", "human", "child"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_girl_boy: {
    keywords: ["home", "parent", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_boy_boy: {
    keywords: ["home", "parent", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC66\u200D\uD83D\uDC66",
    fitzpatrick_scale: false,
    category: "people"
  },
  family_man_girl_girl: {
    keywords: ["home", "parent", "people", "human", "children"],
    char: "\uD83D\uDC68\u200D\uD83D\uDC67\u200D\uD83D\uDC67",
    fitzpatrick_scale: false,
    category: "people"
  },
  yarn: {
    keywords: ["ball", "crochet", "knit"],
    char: "\uD83E\uDDF6",
    fitzpatrick_scale: false,
    category: "people"
  },
  thread: {
    keywords: ["needle", "sewing", "spool", "string"],
    char: "\uD83E\uDDF5",
    fitzpatrick_scale: false,
    category: "people"
  },
  coat: {
    keywords: ["jacket"],
    char: "\uD83E\uDDE5",
    fitzpatrick_scale: false,
    category: "people"
  },
  labcoat: {
    keywords: ["doctor", "experiment", "scientist", "chemist"],
    char: "\uD83E\uDD7C",
    fitzpatrick_scale: false,
    category: "people"
  },
  womans_clothes: {
    keywords: ["fashion", "shopping_bags", "female"],
    char: "\uD83D\uDC5A",
    fitzpatrick_scale: false,
    category: "people"
  },
  tshirt: {
    keywords: ["fashion", "cloth", "casual", "shirt", "tee"],
    char: "\uD83D\uDC55",
    fitzpatrick_scale: false,
    category: "people"
  },
  jeans: {
    keywords: ["fashion", "shopping"],
    char: "\uD83D\uDC56",
    fitzpatrick_scale: false,
    category: "people"
  },
  necktie: {
    keywords: ["shirt", "suitup", "formal", "fashion", "cloth", "business"],
    char: "\uD83D\uDC54",
    fitzpatrick_scale: false,
    category: "people"
  },
  dress: {
    keywords: ["clothes", "fashion", "shopping"],
    char: "\uD83D\uDC57",
    fitzpatrick_scale: false,
    category: "people"
  },
  bikini: {
    keywords: ["swimming", "female", "woman", "girl", "fashion", "beach", "summer"],
    char: "\uD83D\uDC59",
    fitzpatrick_scale: false,
    category: "people"
  },
  kimono: {
    keywords: ["dress", "fashion", "women", "female", "japanese"],
    char: "\uD83D\uDC58",
    fitzpatrick_scale: false,
    category: "people"
  },
  lipstick: {
    keywords: ["female", "girl", "fashion", "woman"],
    char: "\uD83D\uDC84",
    fitzpatrick_scale: false,
    category: "people"
  },
  kiss: {
    keywords: ["face", "lips", "love", "like", "affection", "valentines"],
    char: "\uD83D\uDC8B",
    fitzpatrick_scale: false,
    category: "people"
  },
  footprints: {
    keywords: ["feet", "tracking", "walking", "beach"],
    char: "\uD83D\uDC63",
    fitzpatrick_scale: false,
    category: "people"
  },
  flat_shoe: {
    keywords: ["ballet", "slip-on", "slipper"],
    char: "\uD83E\uDD7F",
    fitzpatrick_scale: false,
    category: "people"
  },
  high_heel: {
    keywords: ["fashion", "shoes", "female", "pumps", "stiletto"],
    char: "\uD83D\uDC60",
    fitzpatrick_scale: false,
    category: "people"
  },
  sandal: {
    keywords: ["shoes", "fashion", "flip flops"],
    char: "\uD83D\uDC61",
    fitzpatrick_scale: false,
    category: "people"
  },
  boot: {
    keywords: ["shoes", "fashion"],
    char: "\uD83D\uDC62",
    fitzpatrick_scale: false,
    category: "people"
  },
  mans_shoe: {
    keywords: ["fashion", "male"],
    char: "\uD83D\uDC5E",
    fitzpatrick_scale: false,
    category: "people"
  },
  athletic_shoe: {
    keywords: ["shoes", "sports", "sneakers"],
    char: "\uD83D\uDC5F",
    fitzpatrick_scale: false,
    category: "people"
  },
  hiking_boot: {
    keywords: ["backpacking", "camping", "hiking"],
    char: "\uD83E\uDD7E",
    fitzpatrick_scale: false,
    category: "people"
  },
  socks: {
    keywords: ["stockings", "clothes"],
    char: "\uD83E\uDDE6",
    fitzpatrick_scale: false,
    category: "people"
  },
  gloves: {
    keywords: ["hands", "winter", "clothes"],
    char: "\uD83E\uDDE4",
    fitzpatrick_scale: false,
    category: "people"
  },
  scarf: {
    keywords: ["neck", "winter", "clothes"],
    char: "\uD83E\uDDE3",
    fitzpatrick_scale: false,
    category: "people"
  },
  womans_hat: {
    keywords: ["fashion", "accessories", "female", "lady", "spring"],
    char: "\uD83D\uDC52",
    fitzpatrick_scale: false,
    category: "people"
  },
  tophat: {
    keywords: ["magic", "gentleman", "classy", "circus"],
    char: "\uD83C\uDFA9",
    fitzpatrick_scale: false,
    category: "people"
  },
  billed_hat: {
    keywords: ["cap", "baseball"],
    char: "\uD83E\uDDE2",
    fitzpatrick_scale: false,
    category: "people"
  },
  rescue_worker_helmet: {
    keywords: ["construction", "build"],
    char: "\u26D1",
    fitzpatrick_scale: false,
    category: "people"
  },
  mortar_board: {
    keywords: ["school", "college", "degree", "university", "graduation", "cap", "hat", "legal", "learn", "education"],
    char: "\uD83C\uDF93",
    fitzpatrick_scale: false,
    category: "people"
  },
  crown: {
    keywords: ["king", "kod", "leader", "royalty", "lord"],
    char: "\uD83D\uDC51",
    fitzpatrick_scale: false,
    category: "people"
  },
  school_satchel: {
    keywords: ["student", "education", "bag", "backpack"],
    char: "\uD83C\uDF92",
    fitzpatrick_scale: false,
    category: "people"
  },
  luggage: {
    keywords: ["packing", "travel"],
    char: "\uD83E\uDDF3",
    fitzpatrick_scale: false,
    category: "people"
  },
  pouch: {
    keywords: ["bag", "accessories", "shopping"],
    char: "\uD83D\uDC5D",
    fitzpatrick_scale: false,
    category: "people"
  },
  purse: {
    keywords: ["fashion", "accessories", "money", "sales", "shopping"],
    char: "\uD83D\uDC5B",
    fitzpatrick_scale: false,
    category: "people"
  },
  handbag: {
    keywords: ["fashion", "accessory", "accessories", "shopping"],
    char: "\uD83D\uDC5C",
    fitzpatrick_scale: false,
    category: "people"
  },
  briefcase: {
    keywords: ["business", "documents", "work", "law", "legal", "job", "career"],
    char: "\uD83D\uDCBC",
    fitzpatrick_scale: false,
    category: "people"
  },
  eyeglasses: {
    keywords: ["fashion", "accessories", "eyesight", "nerdy", "dork", "geek"],
    char: "\uD83D\uDC53",
    fitzpatrick_scale: false,
    category: "people"
  },
  dark_sunglasses: {
    keywords: ["face", "cool", "accessories"],
    char: "\uD83D\uDD76",
    fitzpatrick_scale: false,
    category: "people"
  },
  goggles: {
    keywords: ["eyes", "protection", "safety"],
    char: "\uD83E\uDD7D",
    fitzpatrick_scale: false,
    category: "people"
  },
  ring: {
    keywords: ["wedding", "propose", "marriage", "valentines", "diamond", "fashion", "jewelry", "gem", "engagement"],
    char: "\uD83D\uDC8D",
    fitzpatrick_scale: false,
    category: "people"
  },
  closed_umbrella: {
    keywords: ["weather", "rain", "drizzle"],
    char: "\uD83C\uDF02",
    fitzpatrick_scale: false,
    category: "people"
  },
  dog: {
    keywords: ["animal", "friend", "nature", "woof", "puppy", "pet", "faithful"],
    char: "\uD83D\uDC36",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cat: {
    keywords: ["animal", "meow", "nature", "pet", "kitten"],
    char: "\uD83D\uDC31",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  mouse: {
    keywords: ["animal", "nature", "cheese_wedge", "rodent"],
    char: "\uD83D\uDC2D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hamster: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC39",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  rabbit: {
    keywords: ["animal", "nature", "pet", "spring", "magic", "bunny"],
    char: "\uD83D\uDC30",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  fox_face: {
    keywords: ["animal", "nature", "face"],
    char: "\uD83E\uDD8A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  bear: {
    keywords: ["animal", "nature", "wild"],
    char: "\uD83D\uDC3B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  panda_face: {
    keywords: ["animal", "nature", "panda"],
    char: "\uD83D\uDC3C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  koala: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC28",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  tiger: {
    keywords: ["animal", "cat", "danger", "wild", "nature", "roar"],
    char: "\uD83D\uDC2F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  lion: {
    keywords: ["animal", "nature"],
    char: "\uD83E\uDD81",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cow: {
    keywords: ["beef", "ox", "animal", "nature", "moo", "milk"],
    char: "\uD83D\uDC2E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  pig: {
    keywords: ["animal", "oink", "nature"],
    char: "\uD83D\uDC37",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  pig_nose: {
    keywords: ["animal", "oink"],
    char: "\uD83D\uDC3D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  frog: {
    keywords: ["animal", "nature", "croak", "toad"],
    char: "\uD83D\uDC38",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  squid: {
    keywords: ["animal", "nature", "ocean", "sea"],
    char: "\uD83E\uDD91",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  octopus: {
    keywords: ["animal", "creature", "ocean", "sea", "nature", "beach"],
    char: "\uD83D\uDC19",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  shrimp: {
    keywords: ["animal", "ocean", "nature", "seafood"],
    char: "\uD83E\uDD90",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  monkey_face: {
    keywords: ["animal", "nature", "circus"],
    char: "\uD83D\uDC35",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  gorilla: {
    keywords: ["animal", "nature", "circus"],
    char: "\uD83E\uDD8D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  see_no_evil: {
    keywords: ["monkey", "animal", "nature", "haha"],
    char: "\uD83D\uDE48",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hear_no_evil: {
    keywords: ["animal", "monkey", "nature"],
    char: "\uD83D\uDE49",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  speak_no_evil: {
    keywords: ["monkey", "animal", "nature", "omg"],
    char: "\uD83D\uDE4A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  monkey: {
    keywords: ["animal", "nature", "banana", "circus"],
    char: "\uD83D\uDC12",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  chicken: {
    keywords: ["animal", "cluck", "nature", "bird"],
    char: "\uD83D\uDC14",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  penguin: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC27",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  bird: {
    keywords: ["animal", "nature", "fly", "tweet", "spring"],
    char: "\uD83D\uDC26",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  baby_chick: {
    keywords: ["animal", "chicken", "bird"],
    char: "\uD83D\uDC24",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hatching_chick: {
    keywords: ["animal", "chicken", "egg", "born", "baby", "bird"],
    char: "\uD83D\uDC23",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hatched_chick: {
    keywords: ["animal", "chicken", "baby", "bird"],
    char: "\uD83D\uDC25",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  duck: {
    keywords: ["animal", "nature", "bird", "mallard"],
    char: "\uD83E\uDD86",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  eagle: {
    keywords: ["animal", "nature", "bird"],
    char: "\uD83E\uDD85",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  owl: {
    keywords: ["animal", "nature", "bird", "hoot"],
    char: "\uD83E\uDD89",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  bat: {
    keywords: ["animal", "nature", "blind", "vampire"],
    char: "\uD83E\uDD87",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  wolf: {
    keywords: ["animal", "nature", "wild"],
    char: "\uD83D\uDC3A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  boar: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC17",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  horse: {
    keywords: ["animal", "brown", "nature"],
    char: "\uD83D\uDC34",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  unicorn: {
    keywords: ["animal", "nature", "mystical"],
    char: "\uD83E\uDD84",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  honeybee: {
    keywords: ["animal", "insect", "nature", "bug", "spring", "honey"],
    char: "\uD83D\uDC1D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  bug: {
    keywords: ["animal", "insect", "nature", "worm"],
    char: "\uD83D\uDC1B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  butterfly: {
    keywords: ["animal", "insect", "nature", "caterpillar"],
    char: "\uD83E\uDD8B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  snail: {
    keywords: ["slow", "animal", "shell"],
    char: "\uD83D\uDC0C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  beetle: {
    keywords: ["animal", "insect", "nature", "ladybug"],
    char: "\uD83D\uDC1E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  ant: {
    keywords: ["animal", "insect", "nature", "bug"],
    char: "\uD83D\uDC1C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  grasshopper: {
    keywords: ["animal", "cricket", "chirp"],
    char: "\uD83E\uDD97",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  spider: {
    keywords: ["animal", "arachnid"],
    char: "\uD83D\uDD77",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  scorpion: {
    keywords: ["animal", "arachnid"],
    char: "\uD83E\uDD82",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  crab: {
    keywords: ["animal", "crustacean"],
    char: "\uD83E\uDD80",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  snake: {
    keywords: ["animal", "evil", "nature", "hiss", "python"],
    char: "\uD83D\uDC0D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  lizard: {
    keywords: ["animal", "nature", "reptile"],
    char: "\uD83E\uDD8E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  "t-rex": {
    keywords: ["animal", "nature", "dinosaur", "tyrannosaurus", "extinct"],
    char: "\uD83E\uDD96",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sauropod: {
    keywords: ["animal", "nature", "dinosaur", "brachiosaurus", "brontosaurus", "diplodocus", "extinct"],
    char: "\uD83E\uDD95",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  turtle: {
    keywords: ["animal", "slow", "nature", "tortoise"],
    char: "\uD83D\uDC22",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  tropical_fish: {
    keywords: ["animal", "swim", "ocean", "beach", "nemo"],
    char: "\uD83D\uDC20",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  fish: {
    keywords: ["animal", "food", "nature"],
    char: "\uD83D\uDC1F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  blowfish: {
    keywords: ["animal", "nature", "food", "sea", "ocean"],
    char: "\uD83D\uDC21",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dolphin: {
    keywords: ["animal", "nature", "fish", "sea", "ocean", "flipper", "fins", "beach"],
    char: "\uD83D\uDC2C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  shark: {
    keywords: ["animal", "nature", "fish", "sea", "ocean", "jaws", "fins", "beach"],
    char: "\uD83E\uDD88",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  whale: {
    keywords: ["animal", "nature", "sea", "ocean"],
    char: "\uD83D\uDC33",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  whale2: {
    keywords: ["animal", "nature", "sea", "ocean"],
    char: "\uD83D\uDC0B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  crocodile: {
    keywords: ["animal", "nature", "reptile", "lizard", "alligator"],
    char: "\uD83D\uDC0A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  leopard: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC06",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  zebra: {
    keywords: ["animal", "nature", "stripes", "safari"],
    char: "\uD83E\uDD93",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  tiger2: {
    keywords: ["animal", "nature", "roar"],
    char: "\uD83D\uDC05",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  water_buffalo: {
    keywords: ["animal", "nature", "ox", "cow"],
    char: "\uD83D\uDC03",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  ox: {
    keywords: ["animal", "cow", "beef"],
    char: "\uD83D\uDC02",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cow2: {
    keywords: ["beef", "ox", "animal", "nature", "moo", "milk"],
    char: "\uD83D\uDC04",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  deer: {
    keywords: ["animal", "nature", "horns", "venison"],
    char: "\uD83E\uDD8C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dromedary_camel: {
    keywords: ["animal", "hot", "desert", "hump"],
    char: "\uD83D\uDC2A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  camel: {
    keywords: ["animal", "nature", "hot", "desert", "hump"],
    char: "\uD83D\uDC2B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  giraffe: {
    keywords: ["animal", "nature", "spots", "safari"],
    char: "\uD83E\uDD92",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  elephant: {
    keywords: ["animal", "nature", "nose", "th", "circus"],
    char: "\uD83D\uDC18",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  rhinoceros: {
    keywords: ["animal", "nature", "horn"],
    char: "\uD83E\uDD8F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  goat: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC10",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  ram: {
    keywords: ["animal", "sheep", "nature"],
    char: "\uD83D\uDC0F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sheep: {
    keywords: ["animal", "nature", "wool", "shipit"],
    char: "\uD83D\uDC11",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  racehorse: {
    keywords: ["animal", "gamble", "luck"],
    char: "\uD83D\uDC0E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  pig2: {
    keywords: ["animal", "nature"],
    char: "\uD83D\uDC16",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  rat: {
    keywords: ["animal", "mouse", "rodent"],
    char: "\uD83D\uDC00",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  mouse2: {
    keywords: ["animal", "nature", "rodent"],
    char: "\uD83D\uDC01",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  rooster: {
    keywords: ["animal", "nature", "chicken"],
    char: "\uD83D\uDC13",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  turkey: {
    keywords: ["animal", "bird"],
    char: "\uD83E\uDD83",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dove: {
    keywords: ["animal", "bird"],
    char: "\uD83D\uDD4A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dog2: {
    keywords: ["animal", "nature", "friend", "doge", "pet", "faithful"],
    char: "\uD83D\uDC15",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  poodle: {
    keywords: ["dog", "animal", "101", "nature", "pet"],
    char: "\uD83D\uDC29",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cat2: {
    keywords: ["animal", "meow", "pet", "cats"],
    char: "\uD83D\uDC08",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  rabbit2: {
    keywords: ["animal", "nature", "pet", "magic", "spring"],
    char: "\uD83D\uDC07",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  chipmunk: {
    keywords: ["animal", "nature", "rodent", "squirrel"],
    char: "\uD83D\uDC3F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hedgehog: {
    keywords: ["animal", "nature", "spiny"],
    char: "\uD83E\uDD94",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  raccoon: {
    keywords: ["animal", "nature"],
    char: "\uD83E\uDD9D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  llama: {
    keywords: ["animal", "nature", "alpaca"],
    char: "\uD83E\uDD99",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hippopotamus: {
    keywords: ["animal", "nature"],
    char: "\uD83E\uDD9B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  kangaroo: {
    keywords: ["animal", "nature", "australia", "joey", "hop", "marsupial"],
    char: "\uD83E\uDD98",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  badger: {
    keywords: ["animal", "nature", "honey"],
    char: "\uD83E\uDDA1",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  swan: {
    keywords: ["animal", "nature", "bird"],
    char: "\uD83E\uDDA2",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  peacock: {
    keywords: ["animal", "nature", "peahen", "bird"],
    char: "\uD83E\uDD9A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  parrot: {
    keywords: ["animal", "nature", "bird", "pirate", "talk"],
    char: "\uD83E\uDD9C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  lobster: {
    keywords: ["animal", "nature", "bisque", "claws", "seafood"],
    char: "\uD83E\uDD9E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  mosquito: {
    keywords: ["animal", "nature", "insect", "malaria"],
    char: "\uD83E\uDD9F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  paw_prints: {
    keywords: ["animal", "tracking", "footprints", "dog", "cat", "pet", "feet"],
    char: "\uD83D\uDC3E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dragon: {
    keywords: ["animal", "myth", "nature", "chinese", "green"],
    char: "\uD83D\uDC09",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dragon_face: {
    keywords: ["animal", "myth", "nature", "chinese", "green"],
    char: "\uD83D\uDC32",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cactus: {
    keywords: ["vegetable", "plant", "nature"],
    char: "\uD83C\uDF35",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  christmas_tree: {
    keywords: ["festival", "vacation", "december", "xmas", "celebration"],
    char: "\uD83C\uDF84",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  evergreen_tree: {
    keywords: ["plant", "nature"],
    char: "\uD83C\uDF32",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  deciduous_tree: {
    keywords: ["plant", "nature"],
    char: "\uD83C\uDF33",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  palm_tree: {
    keywords: ["plant", "vegetable", "nature", "summer", "beach", "mojito", "tropical"],
    char: "\uD83C\uDF34",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  seedling: {
    keywords: ["plant", "nature", "grass", "lawn", "spring"],
    char: "\uD83C\uDF31",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  herb: {
    keywords: ["vegetable", "plant", "medicine", "weed", "grass", "lawn"],
    char: "\uD83C\uDF3F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  shamrock: {
    keywords: ["vegetable", "plant", "nature", "irish", "clover"],
    char: "\u2618",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  four_leaf_clover: {
    keywords: ["vegetable", "plant", "nature", "lucky", "irish"],
    char: "\uD83C\uDF40",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  bamboo: {
    keywords: ["plant", "nature", "vegetable", "panda", "pine_decoration"],
    char: "\uD83C\uDF8D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  tanabata_tree: {
    keywords: ["plant", "nature", "branch", "summer"],
    char: "\uD83C\uDF8B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  leaves: {
    keywords: ["nature", "plant", "tree", "vegetable", "grass", "lawn", "spring"],
    char: "\uD83C\uDF43",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  fallen_leaf: {
    keywords: ["nature", "plant", "vegetable", "leaves"],
    char: "\uD83C\uDF42",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  maple_leaf: {
    keywords: ["nature", "plant", "vegetable", "ca", "fall"],
    char: "\uD83C\uDF41",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  ear_of_rice: {
    keywords: ["nature", "plant"],
    char: "\uD83C\uDF3E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  hibiscus: {
    keywords: ["plant", "vegetable", "flowers", "beach"],
    char: "\uD83C\uDF3A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sunflower: {
    keywords: ["nature", "plant", "fall"],
    char: "\uD83C\uDF3B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  rose: {
    keywords: ["flowers", "valentines", "love", "spring"],
    char: "\uD83C\uDF39",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  wilted_flower: {
    keywords: ["plant", "nature", "flower"],
    char: "\uD83E\uDD40",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  tulip: {
    keywords: ["flowers", "plant", "nature", "summer", "spring"],
    char: "\uD83C\uDF37",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  blossom: {
    keywords: ["nature", "flowers", "yellow"],
    char: "\uD83C\uDF3C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cherry_blossom: {
    keywords: ["nature", "plant", "spring", "flower"],
    char: "\uD83C\uDF38",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  bouquet: {
    keywords: ["flowers", "nature", "spring"],
    char: "\uD83D\uDC90",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  mushroom: {
    keywords: ["plant", "vegetable"],
    char: "\uD83C\uDF44",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  chestnut: {
    keywords: ["food", "squirrel"],
    char: "\uD83C\uDF30",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  jack_o_lantern: {
    keywords: ["halloween", "light", "pumpkin", "creepy", "fall"],
    char: "\uD83C\uDF83",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  shell: {
    keywords: ["nature", "sea", "beach"],
    char: "\uD83D\uDC1A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  spider_web: {
    keywords: ["animal", "insect", "arachnid", "silk"],
    char: "\uD83D\uDD78",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  earth_americas: {
    keywords: ["globe", "world", "USA", "international"],
    char: "\uD83C\uDF0E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  earth_africa: {
    keywords: ["globe", "world", "international"],
    char: "\uD83C\uDF0D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  earth_asia: {
    keywords: ["globe", "world", "east", "international"],
    char: "\uD83C\uDF0F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  full_moon: {
    keywords: ["nature", "yellow", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF15",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  waning_gibbous_moon: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep", "waxing_gibbous_moon"],
    char: "\uD83C\uDF16",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  last_quarter_moon: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF17",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  waning_crescent_moon: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF18",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  new_moon: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF11",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  waxing_crescent_moon: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF12",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  first_quarter_moon: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF13",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  waxing_gibbous_moon: {
    keywords: ["nature", "night", "sky", "gray", "twilight", "planet", "space", "evening", "sleep"],
    char: "\uD83C\uDF14",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  new_moon_with_face: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF1A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  full_moon_with_face: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF1D",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  first_quarter_moon_with_face: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF1B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  last_quarter_moon_with_face: {
    keywords: ["nature", "twilight", "planet", "space", "night", "evening", "sleep"],
    char: "\uD83C\uDF1C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sun_with_face: {
    keywords: ["nature", "morning", "sky"],
    char: "\uD83C\uDF1E",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  crescent_moon: {
    keywords: ["night", "sleep", "sky", "evening", "magic"],
    char: "\uD83C\uDF19",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  star: {
    keywords: ["night", "yellow"],
    char: "\u2B50",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  star2: {
    keywords: ["night", "sparkle", "awesome", "good", "magic"],
    char: "\uD83C\uDF1F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dizzy: {
    keywords: ["star", "sparkle", "shoot", "magic"],
    char: "\uD83D\uDCAB",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sparkles: {
    keywords: ["stars", "shine", "shiny", "cool", "awesome", "good", "magic"],
    char: "\u2728",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  comet: {
    keywords: ["space"],
    char: "\u2604",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sunny: {
    keywords: ["weather", "nature", "brightness", "summer", "beach", "spring"],
    char: "\u2600\uFE0F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sun_behind_small_cloud: {
    keywords: ["weather"],
    char: "\uD83C\uDF24",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  partly_sunny: {
    keywords: ["weather", "nature", "cloudy", "morning", "fall", "spring"],
    char: "\u26C5",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sun_behind_large_cloud: {
    keywords: ["weather"],
    char: "\uD83C\uDF25",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sun_behind_rain_cloud: {
    keywords: ["weather"],
    char: "\uD83C\uDF26",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cloud: {
    keywords: ["weather", "sky"],
    char: "\u2601\uFE0F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cloud_with_rain: {
    keywords: ["weather"],
    char: "\uD83C\uDF27",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cloud_with_lightning_and_rain: {
    keywords: ["weather", "lightning"],
    char: "\u26C8",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cloud_with_lightning: {
    keywords: ["weather", "thunder"],
    char: "\uD83C\uDF29",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  zap: {
    keywords: ["thunder", "weather", "lightning bolt", "fast"],
    char: "\u26A1",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  fire: {
    keywords: ["hot", "cook", "flame"],
    char: "\uD83D\uDD25",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  boom: {
    keywords: ["bomb", "explode", "explosion", "collision", "blown"],
    char: "\uD83D\uDCA5",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  snowflake: {
    keywords: ["winter", "season", "cold", "weather", "christmas", "xmas"],
    char: "\u2744\uFE0F",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  cloud_with_snow: {
    keywords: ["weather"],
    char: "\uD83C\uDF28",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  snowman: {
    keywords: ["winter", "season", "cold", "weather", "christmas", "xmas", "frozen", "without_snow"],
    char: "\u26C4",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  snowman_with_snow: {
    keywords: ["winter", "season", "cold", "weather", "christmas", "xmas", "frozen"],
    char: "\u2603",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  wind_face: {
    keywords: ["gust", "air"],
    char: "\uD83C\uDF2C",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  dash: {
    keywords: ["wind", "air", "fast", "shoo", "fart", "smoke", "puff"],
    char: "\uD83D\uDCA8",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  tornado: {
    keywords: ["weather", "cyclone", "twister"],
    char: "\uD83C\uDF2A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  fog: {
    keywords: ["weather"],
    char: "\uD83C\uDF2B",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  open_umbrella: {
    keywords: ["weather", "spring"],
    char: "\u2602",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  umbrella: {
    keywords: ["rainy", "weather", "spring"],
    char: "\u2614",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  droplet: {
    keywords: ["water", "drip", "faucet", "spring"],
    char: "\uD83D\uDCA7",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  sweat_drops: {
    keywords: ["water", "drip", "oops"],
    char: "\uD83D\uDCA6",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  ocean: {
    keywords: ["sea", "water", "wave", "nature", "tsunami", "disaster"],
    char: "\uD83C\uDF0A",
    fitzpatrick_scale: false,
    category: "animals_and_nature"
  },
  green_apple: {
    keywords: ["fruit", "nature"],
    char: "\uD83C\uDF4F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  apple: {
    keywords: ["fruit", "mac", "school"],
    char: "\uD83C\uDF4E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  pear: {
    keywords: ["fruit", "nature", "food"],
    char: "\uD83C\uDF50",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  tangerine: {
    keywords: ["food", "fruit", "nature", "orange"],
    char: "\uD83C\uDF4A",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  lemon: {
    keywords: ["fruit", "nature"],
    char: "\uD83C\uDF4B",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  banana: {
    keywords: ["fruit", "food", "monkey"],
    char: "\uD83C\uDF4C",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  watermelon: {
    keywords: ["fruit", "food", "picnic", "summer"],
    char: "\uD83C\uDF49",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  grapes: {
    keywords: ["fruit", "food", "wine"],
    char: "\uD83C\uDF47",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  strawberry: {
    keywords: ["fruit", "food", "nature"],
    char: "\uD83C\uDF53",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  melon: {
    keywords: ["fruit", "nature", "food"],
    char: "\uD83C\uDF48",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cherries: {
    keywords: ["food", "fruit"],
    char: "\uD83C\uDF52",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  peach: {
    keywords: ["fruit", "nature", "food"],
    char: "\uD83C\uDF51",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  pineapple: {
    keywords: ["fruit", "nature", "food"],
    char: "\uD83C\uDF4D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  coconut: {
    keywords: ["fruit", "nature", "food", "palm"],
    char: "\uD83E\uDD65",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  kiwi_fruit: {
    keywords: ["fruit", "food"],
    char: "\uD83E\uDD5D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  mango: {
    keywords: ["fruit", "food", "tropical"],
    char: "\uD83E\uDD6D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  avocado: {
    keywords: ["fruit", "food"],
    char: "\uD83E\uDD51",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  broccoli: {
    keywords: ["fruit", "food", "vegetable"],
    char: "\uD83E\uDD66",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  tomato: {
    keywords: ["fruit", "vegetable", "nature", "food"],
    char: "\uD83C\uDF45",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  eggplant: {
    keywords: ["vegetable", "nature", "food", "aubergine"],
    char: "\uD83C\uDF46",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cucumber: {
    keywords: ["fruit", "food", "pickle"],
    char: "\uD83E\uDD52",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  carrot: {
    keywords: ["vegetable", "food", "orange"],
    char: "\uD83E\uDD55",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  hot_pepper: {
    keywords: ["food", "spicy", "chilli", "chili"],
    char: "\uD83C\uDF36",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  potato: {
    keywords: ["food", "tuber", "vegatable", "starch"],
    char: "\uD83E\uDD54",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  corn: {
    keywords: ["food", "vegetable", "plant"],
    char: "\uD83C\uDF3D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  leafy_greens: {
    keywords: ["food", "vegetable", "plant", "bok choy", "cabbage", "kale", "lettuce"],
    char: "\uD83E\uDD6C",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  sweet_potato: {
    keywords: ["food", "nature"],
    char: "\uD83C\uDF60",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  peanuts: {
    keywords: ["food", "nut"],
    char: "\uD83E\uDD5C",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  honey_pot: {
    keywords: ["bees", "sweet", "kitchen"],
    char: "\uD83C\uDF6F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  croissant: {
    keywords: ["food", "bread", "french"],
    char: "\uD83E\uDD50",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  bread: {
    keywords: ["food", "wheat", "breakfast", "toast"],
    char: "\uD83C\uDF5E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  baguette_bread: {
    keywords: ["food", "bread", "french"],
    char: "\uD83E\uDD56",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  bagel: {
    keywords: ["food", "bread", "bakery", "schmear"],
    char: "\uD83E\uDD6F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  pretzel: {
    keywords: ["food", "bread", "twisted"],
    char: "\uD83E\uDD68",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cheese: {
    keywords: ["food", "chadder"],
    char: "\uD83E\uDDC0",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  egg: {
    keywords: ["food", "chicken", "breakfast"],
    char: "\uD83E\uDD5A",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  bacon: {
    keywords: ["food", "breakfast", "pork", "pig", "meat"],
    char: "\uD83E\uDD53",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  steak: {
    keywords: ["food", "cow", "meat", "cut", "chop", "lambchop", "porkchop"],
    char: "\uD83E\uDD69",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  pancakes: {
    keywords: ["food", "breakfast", "flapjacks", "hotcakes"],
    char: "\uD83E\uDD5E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  poultry_leg: {
    keywords: ["food", "meat", "drumstick", "bird", "chicken", "turkey"],
    char: "\uD83C\uDF57",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  meat_on_bone: {
    keywords: ["good", "food", "drumstick"],
    char: "\uD83C\uDF56",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  bone: {
    keywords: ["skeleton"],
    char: "\uD83E\uDDB4",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  fried_shrimp: {
    keywords: ["food", "animal", "appetizer", "summer"],
    char: "\uD83C\uDF64",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  fried_egg: {
    keywords: ["food", "breakfast", "kitchen", "egg"],
    char: "\uD83C\uDF73",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  hamburger: {
    keywords: ["meat", "fast food", "beef", "cheeseburger", "mcdonalds", "burger king"],
    char: "\uD83C\uDF54",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  fries: {
    keywords: ["chips", "snack", "fast food"],
    char: "\uD83C\uDF5F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  stuffed_flatbread: {
    keywords: ["food", "flatbread", "stuffed", "gyro"],
    char: "\uD83E\uDD59",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  hotdog: {
    keywords: ["food", "frankfurter"],
    char: "\uD83C\uDF2D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  pizza: {
    keywords: ["food", "party"],
    char: "\uD83C\uDF55",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  sandwich: {
    keywords: ["food", "lunch", "bread"],
    char: "\uD83E\uDD6A",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  canned_food: {
    keywords: ["food", "soup"],
    char: "\uD83E\uDD6B",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  spaghetti: {
    keywords: ["food", "italian", "noodle"],
    char: "\uD83C\uDF5D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  taco: {
    keywords: ["food", "mexican"],
    char: "\uD83C\uDF2E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  burrito: {
    keywords: ["food", "mexican"],
    char: "\uD83C\uDF2F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  green_salad: {
    keywords: ["food", "healthy", "lettuce"],
    char: "\uD83E\uDD57",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  shallow_pan_of_food: {
    keywords: ["food", "cooking", "casserole", "paella"],
    char: "\uD83E\uDD58",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  ramen: {
    keywords: ["food", "japanese", "noodle", "chopsticks"],
    char: "\uD83C\uDF5C",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  stew: {
    keywords: ["food", "meat", "soup"],
    char: "\uD83C\uDF72",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  fish_cake: {
    keywords: ["food", "japan", "sea", "beach", "narutomaki", "pink", "swirl", "kamaboko", "surimi", "ramen"],
    char: "\uD83C\uDF65",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  fortune_cookie: {
    keywords: ["food", "prophecy"],
    char: "\uD83E\uDD60",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  sushi: {
    keywords: ["food", "fish", "japanese", "rice"],
    char: "\uD83C\uDF63",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  bento: {
    keywords: ["food", "japanese", "box"],
    char: "\uD83C\uDF71",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  curry: {
    keywords: ["food", "spicy", "hot", "indian"],
    char: "\uD83C\uDF5B",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  rice_ball: {
    keywords: ["food", "japanese"],
    char: "\uD83C\uDF59",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  rice: {
    keywords: ["food", "china", "asian"],
    char: "\uD83C\uDF5A",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  rice_cracker: {
    keywords: ["food", "japanese"],
    char: "\uD83C\uDF58",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  oden: {
    keywords: ["food", "japanese"],
    char: "\uD83C\uDF62",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  dango: {
    keywords: ["food", "dessert", "sweet", "japanese", "barbecue", "meat"],
    char: "\uD83C\uDF61",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  shaved_ice: {
    keywords: ["hot", "dessert", "summer"],
    char: "\uD83C\uDF67",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  ice_cream: {
    keywords: ["food", "hot", "dessert"],
    char: "\uD83C\uDF68",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  icecream: {
    keywords: ["food", "hot", "dessert", "summer"],
    char: "\uD83C\uDF66",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  pie: {
    keywords: ["food", "dessert", "pastry"],
    char: "\uD83E\uDD67",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cake: {
    keywords: ["food", "dessert"],
    char: "\uD83C\uDF70",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cupcake: {
    keywords: ["food", "dessert", "bakery", "sweet"],
    char: "\uD83E\uDDC1",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  moon_cake: {
    keywords: ["food", "autumn"],
    char: "\uD83E\uDD6E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  birthday: {
    keywords: ["food", "dessert", "cake"],
    char: "\uD83C\uDF82",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  custard: {
    keywords: ["dessert", "food"],
    char: "\uD83C\uDF6E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  candy: {
    keywords: ["snack", "dessert", "sweet", "lolly"],
    char: "\uD83C\uDF6C",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  lollipop: {
    keywords: ["food", "snack", "candy", "sweet"],
    char: "\uD83C\uDF6D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  chocolate_bar: {
    keywords: ["food", "snack", "dessert", "sweet"],
    char: "\uD83C\uDF6B",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  popcorn: {
    keywords: ["food", "movie theater", "films", "snack"],
    char: "\uD83C\uDF7F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  dumpling: {
    keywords: ["food", "empanada", "pierogi", "potsticker"],
    char: "\uD83E\uDD5F",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  doughnut: {
    keywords: ["food", "dessert", "snack", "sweet", "donut"],
    char: "\uD83C\uDF69",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cookie: {
    keywords: ["food", "snack", "oreo", "chocolate", "sweet", "dessert"],
    char: "\uD83C\uDF6A",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  milk_glass: {
    keywords: ["beverage", "drink", "cow"],
    char: "\uD83E\uDD5B",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  beer: {
    keywords: ["relax", "beverage", "drink", "drunk", "party", "pub", "summer", "alcohol", "booze"],
    char: "\uD83C\uDF7A",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  beers: {
    keywords: ["relax", "beverage", "drink", "drunk", "party", "pub", "summer", "alcohol", "booze"],
    char: "\uD83C\uDF7B",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  clinking_glasses: {
    keywords: ["beverage", "drink", "party", "alcohol", "celebrate", "cheers", "wine", "champagne", "toast"],
    char: "\uD83E\uDD42",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  wine_glass: {
    keywords: ["drink", "beverage", "drunk", "alcohol", "booze"],
    char: "\uD83C\uDF77",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  tumbler_glass: {
    keywords: ["drink", "beverage", "drunk", "alcohol", "liquor", "booze", "bourbon", "scotch", "whisky", "glass", "shot"],
    char: "\uD83E\uDD43",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cocktail: {
    keywords: ["drink", "drunk", "alcohol", "beverage", "booze", "mojito"],
    char: "\uD83C\uDF78",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  tropical_drink: {
    keywords: ["beverage", "cocktail", "summer", "beach", "alcohol", "booze", "mojito"],
    char: "\uD83C\uDF79",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  champagne: {
    keywords: ["drink", "wine", "bottle", "celebration"],
    char: "\uD83C\uDF7E",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  sake: {
    keywords: ["wine", "drink", "drunk", "beverage", "japanese", "alcohol", "booze"],
    char: "\uD83C\uDF76",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  tea: {
    keywords: ["drink", "bowl", "breakfast", "green", "british"],
    char: "\uD83C\uDF75",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  cup_with_straw: {
    keywords: ["drink", "soda"],
    char: "\uD83E\uDD64",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  coffee: {
    keywords: ["beverage", "caffeine", "latte", "espresso"],
    char: "\u2615",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  baby_bottle: {
    keywords: ["food", "container", "milk"],
    char: "\uD83C\uDF7C",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  salt: {
    keywords: ["condiment", "shaker"],
    char: "\uD83E\uDDC2",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  spoon: {
    keywords: ["cutlery", "kitchen", "tableware"],
    char: "\uD83E\uDD44",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  fork_and_knife: {
    keywords: ["cutlery", "kitchen"],
    char: "\uD83C\uDF74",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  plate_with_cutlery: {
    keywords: ["food", "eat", "meal", "lunch", "dinner", "restaurant"],
    char: "\uD83C\uDF7D",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  bowl_with_spoon: {
    keywords: ["food", "breakfast", "cereal", "oatmeal", "porridge"],
    char: "\uD83E\uDD63",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  takeout_box: {
    keywords: ["food", "leftovers"],
    char: "\uD83E\uDD61",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  chopsticks: {
    keywords: ["food"],
    char: "\uD83E\uDD62",
    fitzpatrick_scale: false,
    category: "food_and_drink"
  },
  soccer: {
    keywords: ["sports", "football"],
    char: "\u26BD",
    fitzpatrick_scale: false,
    category: "activity"
  },
  basketball: {
    keywords: ["sports", "balls", "NBA"],
    char: "\uD83C\uDFC0",
    fitzpatrick_scale: false,
    category: "activity"
  },
  football: {
    keywords: ["sports", "balls", "NFL"],
    char: "\uD83C\uDFC8",
    fitzpatrick_scale: false,
    category: "activity"
  },
  baseball: {
    keywords: ["sports", "balls"],
    char: "\u26BE",
    fitzpatrick_scale: false,
    category: "activity"
  },
  softball: {
    keywords: ["sports", "balls"],
    char: "\uD83E\uDD4E",
    fitzpatrick_scale: false,
    category: "activity"
  },
  tennis: {
    keywords: ["sports", "balls", "green"],
    char: "\uD83C\uDFBE",
    fitzpatrick_scale: false,
    category: "activity"
  },
  volleyball: {
    keywords: ["sports", "balls"],
    char: "\uD83C\uDFD0",
    fitzpatrick_scale: false,
    category: "activity"
  },
  rugby_football: {
    keywords: ["sports", "team"],
    char: "\uD83C\uDFC9",
    fitzpatrick_scale: false,
    category: "activity"
  },
  flying_disc: {
    keywords: ["sports", "frisbee", "ultimate"],
    char: "\uD83E\uDD4F",
    fitzpatrick_scale: false,
    category: "activity"
  },
  "8ball": {
    keywords: ["pool", "hobby", "game", "luck", "magic"],
    char: "\uD83C\uDFB1",
    fitzpatrick_scale: false,
    category: "activity"
  },
  golf: {
    keywords: ["sports", "business", "flag", "hole", "summer"],
    char: "\u26F3",
    fitzpatrick_scale: false,
    category: "activity"
  },
  golfing_woman: {
    keywords: ["sports", "business", "woman", "female"],
    char: "\uD83C\uDFCC\uFE0F\u200D\u2640\uFE0F",
    fitzpatrick_scale: false,
    category: "activity"
  },
  golfing_man: {
    keywords: ["sports", "business"],
    char: "\uD83C\uDFCC",
    fitzpatrick_scale: true,
    category: "activity"
  },
  ping_pong: {
    keywords: ["sports", "pingpong"],
    char: "\uD83C\uDFD3",
    fitzpatrick_scale: false,
    category: "activity"
  },
  badminton: {
    keywords: ["sports"],
    char: "\uD83C\uDFF8",
    fitzpatrick_scale: false,
    category: "activity"
  },
  goal_net: {
    keywords: ["sports"],
    char: "\uD83E\uDD45",
    fitzpatrick_scale: false,
    category: "activity"
  },
  ice_hockey: {
    keywords: ["sports"],
    char: "\uD83C\uDFD2",
    fitzpatrick_scale: false,
    category: "activity"
  },
  field_hockey: {
    keywords: ["sports"],
    char: "\uD83C\uDFD1",
    fitzpatrick_scale: false,
    category: "activity"
  },
  lacrosse: {
    keywords: ["sports", "ball", "stick"],
    char: "\uD83E\uDD4D",
    fitzpatrick_scale: false,
    category: "activity"
  },
  cricket: {
    keywords: ["sports"],
    char: "\uD83C\uDFCF",
    fitzpatrick_scale: false,
    category: "activity"
  },
  ski: {
    keywords: ["sports", "winter", "cold", "snow"],
    char: "\uD83C\uDFBF",
    fitzpatrick_scale: false,
    category: "activity"
  },
  skier: {
    keywords: ["sports", "winter", "snow"],
    char: "\u26F7",
    fitzpatrick_scale: false,
    category: "activity"
  },
  snowboarder: {
    keywords: ["sports", "winter"],
    char: "\uD83C\uDFC2",
    fitzpatrick_scale: true,
    category: "activity"
  },
  person_fencing: {
    keywords: ["sports", "fencing", "sword"],
    char: "\uD83E\uDD3A",
    fitzpatrick_scale: false,
    category: "activity"
  },
  women_wrestling: {
    keywords: ["sports", "wrestlers"],
    char: "\uD83E\uDD3C\u200D\u2640\uFE0F",
    fitzpatrick_scale: false,
    category: "activity"
  },
  men_wrestling: {
    keywords: ["sports", "wrestlers"],
    char: "\uD83E\uDD3C\u200D\u2642\uFE0F",
    fitzpatrick_scale: false,
    category: "activity"
  },
  woman_cartwheeling: {
    keywords: ["gymnastics"],
    char: "\uD83E\uDD38\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  man_cartwheeling: {
    keywords: ["gymnastics"],
    char: "\uD83E\uDD38\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  woman_playing_handball: {
    keywords: ["sports"],
    char: "\uD83E\uDD3E\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  man_playing_handball: {
    keywords: ["sports"],
    char: "\uD83E\uDD3E\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  ice_skate: {
    keywords: ["sports"],
    char: "\u26F8",
    fitzpatrick_scale: false,
    category: "activity"
  },
  curling_stone: {
    keywords: ["sports"],
    char: "\uD83E\uDD4C",
    fitzpatrick_scale: false,
    category: "activity"
  },
  skateboard: {
    keywords: ["board"],
    char: "\uD83D\uDEF9",
    fitzpatrick_scale: false,
    category: "activity"
  },
  sled: {
    keywords: ["sleigh", "luge", "toboggan"],
    char: "\uD83D\uDEF7",
    fitzpatrick_scale: false,
    category: "activity"
  },
  bow_and_arrow: {
    keywords: ["sports"],
    char: "\uD83C\uDFF9",
    fitzpatrick_scale: false,
    category: "activity"
  },
  fishing_pole_and_fish: {
    keywords: ["food", "hobby", "summer"],
    char: "\uD83C\uDFA3",
    fitzpatrick_scale: false,
    category: "activity"
  },
  boxing_glove: {
    keywords: ["sports", "fighting"],
    char: "\uD83E\uDD4A",
    fitzpatrick_scale: false,
    category: "activity"
  },
  martial_arts_uniform: {
    keywords: ["judo", "karate", "taekwondo"],
    char: "\uD83E\uDD4B",
    fitzpatrick_scale: false,
    category: "activity"
  },
  rowing_woman: {
    keywords: ["sports", "hobby", "water", "ship", "woman", "female"],
    char: "\uD83D\uDEA3\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  rowing_man: {
    keywords: ["sports", "hobby", "water", "ship"],
    char: "\uD83D\uDEA3",
    fitzpatrick_scale: true,
    category: "activity"
  },
  climbing_woman: {
    keywords: ["sports", "hobby", "woman", "female", "rock"],
    char: "\uD83E\uDDD7\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  climbing_man: {
    keywords: ["sports", "hobby", "man", "male", "rock"],
    char: "\uD83E\uDDD7\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  swimming_woman: {
    keywords: ["sports", "exercise", "human", "athlete", "water", "summer", "woman", "female"],
    char: "\uD83C\uDFCA\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  swimming_man: {
    keywords: ["sports", "exercise", "human", "athlete", "water", "summer"],
    char: "\uD83C\uDFCA",
    fitzpatrick_scale: true,
    category: "activity"
  },
  woman_playing_water_polo: {
    keywords: ["sports", "pool"],
    char: "\uD83E\uDD3D\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  man_playing_water_polo: {
    keywords: ["sports", "pool"],
    char: "\uD83E\uDD3D\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  woman_in_lotus_position: {
    keywords: ["woman", "female", "meditation", "yoga", "serenity", "zen", "mindfulness"],
    char: "\uD83E\uDDD8\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  man_in_lotus_position: {
    keywords: ["man", "male", "meditation", "yoga", "serenity", "zen", "mindfulness"],
    char: "\uD83E\uDDD8\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  surfing_woman: {
    keywords: ["sports", "ocean", "sea", "summer", "beach", "woman", "female"],
    char: "\uD83C\uDFC4\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  surfing_man: {
    keywords: ["sports", "ocean", "sea", "summer", "beach"],
    char: "\uD83C\uDFC4",
    fitzpatrick_scale: true,
    category: "activity"
  },
  bath: {
    keywords: ["clean", "shower", "bathroom"],
    char: "\uD83D\uDEC0",
    fitzpatrick_scale: true,
    category: "activity"
  },
  basketball_woman: {
    keywords: ["sports", "human", "woman", "female"],
    char: "\u26F9\uFE0F\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  basketball_man: {
    keywords: ["sports", "human"],
    char: "\u26F9",
    fitzpatrick_scale: true,
    category: "activity"
  },
  weight_lifting_woman: {
    keywords: ["sports", "training", "exercise", "woman", "female"],
    char: "\uD83C\uDFCB\uFE0F\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  weight_lifting_man: {
    keywords: ["sports", "training", "exercise"],
    char: "\uD83C\uDFCB",
    fitzpatrick_scale: true,
    category: "activity"
  },
  biking_woman: {
    keywords: ["sports", "bike", "exercise", "hipster", "woman", "female"],
    char: "\uD83D\uDEB4\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  biking_man: {
    keywords: ["sports", "bike", "exercise", "hipster"],
    char: "\uD83D\uDEB4",
    fitzpatrick_scale: true,
    category: "activity"
  },
  mountain_biking_woman: {
    keywords: ["transportation", "sports", "human", "race", "bike", "woman", "female"],
    char: "\uD83D\uDEB5\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  mountain_biking_man: {
    keywords: ["transportation", "sports", "human", "race", "bike"],
    char: "\uD83D\uDEB5",
    fitzpatrick_scale: true,
    category: "activity"
  },
  horse_racing: {
    keywords: ["animal", "betting", "competition", "gambling", "luck"],
    char: "\uD83C\uDFC7",
    fitzpatrick_scale: true,
    category: "activity"
  },
  business_suit_levitating: {
    keywords: ["suit", "business", "levitate", "hover", "jump"],
    char: "\uD83D\uDD74",
    fitzpatrick_scale: true,
    category: "activity"
  },
  trophy: {
    keywords: ["win", "award", "contest", "place", "ftw", "ceremony"],
    char: "\uD83C\uDFC6",
    fitzpatrick_scale: false,
    category: "activity"
  },
  running_shirt_with_sash: {
    keywords: ["play", "pageant"],
    char: "\uD83C\uDFBD",
    fitzpatrick_scale: false,
    category: "activity"
  },
  medal_sports: {
    keywords: ["award", "winning"],
    char: "\uD83C\uDFC5",
    fitzpatrick_scale: false,
    category: "activity"
  },
  medal_military: {
    keywords: ["award", "winning", "army"],
    char: "\uD83C\uDF96",
    fitzpatrick_scale: false,
    category: "activity"
  },
  "1st_place_medal": {
    keywords: ["award", "winning", "first"],
    char: "\uD83E\uDD47",
    fitzpatrick_scale: false,
    category: "activity"
  },
  "2nd_place_medal": {
    keywords: ["award", "second"],
    char: "\uD83E\uDD48",
    fitzpatrick_scale: false,
    category: "activity"
  },
  "3rd_place_medal": {
    keywords: ["award", "third"],
    char: "\uD83E\uDD49",
    fitzpatrick_scale: false,
    category: "activity"
  },
  reminder_ribbon: {
    keywords: ["sports", "cause", "support", "awareness"],
    char: "\uD83C\uDF97",
    fitzpatrick_scale: false,
    category: "activity"
  },
  rosette: {
    keywords: ["flower", "decoration", "military"],
    char: "\uD83C\uDFF5",
    fitzpatrick_scale: false,
    category: "activity"
  },
  ticket: {
    keywords: ["event", "concert", "pass"],
    char: "\uD83C\uDFAB",
    fitzpatrick_scale: false,
    category: "activity"
  },
  tickets: {
    keywords: ["sports", "concert", "entrance"],
    char: "\uD83C\uDF9F",
    fitzpatrick_scale: false,
    category: "activity"
  },
  performing_arts: {
    keywords: ["acting", "theater", "drama"],
    char: "\uD83C\uDFAD",
    fitzpatrick_scale: false,
    category: "activity"
  },
  art: {
    keywords: ["design", "paint", "draw", "colors"],
    char: "\uD83C\uDFA8",
    fitzpatrick_scale: false,
    category: "activity"
  },
  circus_tent: {
    keywords: ["festival", "carnival", "party"],
    char: "\uD83C\uDFAA",
    fitzpatrick_scale: false,
    category: "activity"
  },
  woman_juggling: {
    keywords: ["juggle", "balance", "skill", "multitask"],
    char: "\uD83E\uDD39\u200D\u2640\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  man_juggling: {
    keywords: ["juggle", "balance", "skill", "multitask"],
    char: "\uD83E\uDD39\u200D\u2642\uFE0F",
    fitzpatrick_scale: true,
    category: "activity"
  },
  microphone: {
    keywords: ["sound", "music", "PA", "sing", "talkshow"],
    char: "\uD83C\uDFA4",
    fitzpatrick_scale: false,
    category: "activity"
  },
  headphones: {
    keywords: ["music", "score", "gadgets"],
    char: "\uD83C\uDFA7",
    fitzpatrick_scale: false,
    category: "activity"
  },
  musical_score: {
    keywords: ["treble", "clef", "compose"],
    char: "\uD83C\uDFBC",
    fitzpatrick_scale: false,
    category: "activity"
  },
  musical_keyboard: {
    keywords: ["piano", "instrument", "compose"],
    char: "\uD83C\uDFB9",
    fitzpatrick_scale: false,
    category: "activity"
  },
  drum: {
    keywords: ["music", "instrument", "drumsticks", "snare"],
    char: "\uD83E\uDD41",
    fitzpatrick_scale: false,
    category: "activity"
  },
  saxophone: {
    keywords: ["music", "instrument", "jazz", "blues"],
    char: "\uD83C\uDFB7",
    fitzpatrick_scale: false,
    category: "activity"
  },
  trumpet: {
    keywords: ["music", "brass"],
    char: "\uD83C\uDFBA",
    fitzpatrick_scale: false,
    category: "activity"
  },
  guitar: {
    keywords: ["music", "instrument"],
    char: "\uD83C\uDFB8",
    fitzpatrick_scale: false,
    category: "activity"
  },
  violin: {
    keywords: ["music", "instrument", "orchestra", "symphony"],
    char: "\uD83C\uDFBB",
    fitzpatrick_scale: false,
    category: "activity"
  },
  clapper: {
    keywords: ["movie", "film", "record"],
    char: "\uD83C\uDFAC",
    fitzpatrick_scale: false,
    category: "activity"
  },
  video_game: {
    keywords: ["play", "console", "PS4", "controller"],
    char: "\uD83C\uDFAE",
    fitzpatrick_scale: false,
    category: "activity"
  },
  space_invader: {
    keywords: ["game", "arcade", "play"],
    char: "\uD83D\uDC7E",
    fitzpatrick_scale: false,
    category: "activity"
  },
  dart: {
    keywords: ["game", "play", "bar", "target", "bullseye"],
    char: "\uD83C\uDFAF",
    fitzpatrick_scale: false,
    category: "activity"
  },
  game_die: {
    keywords: ["dice", "random", "tabletop", "play", "luck"],
    char: "\uD83C\uDFB2",
    fitzpatrick_scale: false,
    category: "activity"
  },
  chess_pawn: {
    keywords: ["expendable"],
    char: "\u265F",
    fitzpatrick_scale: false,
    category: "activity"
  },
  slot_machine: {
    keywords: ["bet", "gamble", "vegas", "fruit machine", "luck", "casino"],
    char: "\uD83C\uDFB0",
    fitzpatrick_scale: false,
    category: "activity"
  },
  jigsaw: {
    keywords: ["interlocking", "puzzle", "piece"],
    char: "\uD83E\uDDE9",
    fitzpatrick_scale: false,
    category: "activity"
  },
  bowling: {
    keywords: ["sports", "fun", "play"],
    char: "\uD83C\uDFB3",
    fitzpatrick_scale: false,
    category: "activity"
  },
  red_car: {
    keywords: ["red", "transportation", "vehicle"],
    char: "\uD83D\uDE97",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  taxi: {
    keywords: ["uber", "vehicle", "cars", "transportation"],
    char: "\uD83D\uDE95",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  blue_car: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE99",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  bus: {
    keywords: ["car", "vehicle", "transportation"],
    char: "\uD83D\uDE8C",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  trolleybus: {
    keywords: ["bart", "transportation", "vehicle"],
    char: "\uD83D\uDE8E",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  racing_car: {
    keywords: ["sports", "race", "fast", "formula", "f1"],
    char: "\uD83C\uDFCE",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  police_car: {
    keywords: ["vehicle", "cars", "transportation", "law", "legal", "enforcement"],
    char: "\uD83D\uDE93",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  ambulance: {
    keywords: ["health", "911", "hospital"],
    char: "\uD83D\uDE91",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  fire_engine: {
    keywords: ["transportation", "cars", "vehicle"],
    char: "\uD83D\uDE92",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  minibus: {
    keywords: ["vehicle", "car", "transportation"],
    char: "\uD83D\uDE90",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  truck: {
    keywords: ["cars", "transportation"],
    char: "\uD83D\uDE9A",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  articulated_lorry: {
    keywords: ["vehicle", "cars", "transportation", "express"],
    char: "\uD83D\uDE9B",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  tractor: {
    keywords: ["vehicle", "car", "farming", "agriculture"],
    char: "\uD83D\uDE9C",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  kick_scooter: {
    keywords: ["vehicle", "kick", "razor"],
    char: "\uD83D\uDEF4",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  motorcycle: {
    keywords: ["race", "sports", "fast"],
    char: "\uD83C\uDFCD",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  bike: {
    keywords: ["sports", "bicycle", "exercise", "hipster"],
    char: "\uD83D\uDEB2",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  motor_scooter: {
    keywords: ["vehicle", "vespa", "sasha"],
    char: "\uD83D\uDEF5",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  rotating_light: {
    keywords: ["police", "ambulance", "911", "emergency", "alert", "error", "pinged", "law", "legal"],
    char: "\uD83D\uDEA8",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  oncoming_police_car: {
    keywords: ["vehicle", "law", "legal", "enforcement", "911"],
    char: "\uD83D\uDE94",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  oncoming_bus: {
    keywords: ["vehicle", "transportation"],
    char: "\uD83D\uDE8D",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  oncoming_automobile: {
    keywords: ["car", "vehicle", "transportation"],
    char: "\uD83D\uDE98",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  oncoming_taxi: {
    keywords: ["vehicle", "cars", "uber"],
    char: "\uD83D\uDE96",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  aerial_tramway: {
    keywords: ["transportation", "vehicle", "ski"],
    char: "\uD83D\uDEA1",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  mountain_cableway: {
    keywords: ["transportation", "vehicle", "ski"],
    char: "\uD83D\uDEA0",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  suspension_railway: {
    keywords: ["vehicle", "transportation"],
    char: "\uD83D\uDE9F",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  railway_car: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE83",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  train: {
    keywords: ["transportation", "vehicle", "carriage", "public", "travel"],
    char: "\uD83D\uDE8B",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  monorail: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE9D",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  bullettrain_side: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE84",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  bullettrain_front: {
    keywords: ["transportation", "vehicle", "speed", "fast", "public", "travel"],
    char: "\uD83D\uDE85",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  light_rail: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE88",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  mountain_railway: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE9E",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  steam_locomotive: {
    keywords: ["transportation", "vehicle", "train"],
    char: "\uD83D\uDE82",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  train2: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE86",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  metro: {
    keywords: ["transportation", "blue-square", "mrt", "underground", "tube"],
    char: "\uD83D\uDE87",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  tram: {
    keywords: ["transportation", "vehicle"],
    char: "\uD83D\uDE8A",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  station: {
    keywords: ["transportation", "vehicle", "public"],
    char: "\uD83D\uDE89",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  flying_saucer: {
    keywords: ["transportation", "vehicle", "ufo"],
    char: "\uD83D\uDEF8",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  helicopter: {
    keywords: ["transportation", "vehicle", "fly"],
    char: "\uD83D\uDE81",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  small_airplane: {
    keywords: ["flight", "transportation", "fly", "vehicle"],
    char: "\uD83D\uDEE9",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  airplane: {
    keywords: ["vehicle", "transportation", "flight", "fly"],
    char: "\u2708\uFE0F",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  flight_departure: {
    keywords: ["airport", "flight", "landing"],
    char: "\uD83D\uDEEB",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  flight_arrival: {
    keywords: ["airport", "flight", "boarding"],
    char: "\uD83D\uDEEC",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  sailboat: {
    keywords: ["ship", "summer", "transportation", "water", "sailing"],
    char: "\u26F5",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  motor_boat: {
    keywords: ["ship"],
    char: "\uD83D\uDEE5",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  speedboat: {
    keywords: ["ship", "transportation", "vehicle", "summer"],
    char: "\uD83D\uDEA4",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  ferry: {
    keywords: ["boat", "ship", "yacht"],
    char: "\u26F4",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  passenger_ship: {
    keywords: ["yacht", "cruise", "ferry"],
    char: "\uD83D\uDEF3",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  rocket: {
    keywords: ["launch", "ship", "staffmode", "NASA", "outer space", "outer_space", "fly"],
    char: "\uD83D\uDE80",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  artificial_satellite: {
    keywords: ["communication", "gps", "orbit", "spaceflight", "NASA", "ISS"],
    char: "\uD83D\uDEF0",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  seat: {
    keywords: ["sit", "airplane", "transport", "bus", "flight", "fly"],
    char: "\uD83D\uDCBA",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  canoe: {
    keywords: ["boat", "paddle", "water", "ship"],
    char: "\uD83D\uDEF6",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  anchor: {
    keywords: ["ship", "ferry", "sea", "boat"],
    char: "\u2693",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  construction: {
    keywords: ["wip", "progress", "caution", "warning"],
    char: "\uD83D\uDEA7",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  fuelpump: {
    keywords: ["gas station", "petroleum"],
    char: "\u26FD",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  busstop: {
    keywords: ["transportation", "wait"],
    char: "\uD83D\uDE8F",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  vertical_traffic_light: {
    keywords: ["transportation", "driving"],
    char: "\uD83D\uDEA6",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  traffic_light: {
    keywords: ["transportation", "signal"],
    char: "\uD83D\uDEA5",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  checkered_flag: {
    keywords: ["contest", "finishline", "race", "gokart"],
    char: "\uD83C\uDFC1",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  ship: {
    keywords: ["transportation", "titanic", "deploy"],
    char: "\uD83D\uDEA2",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  ferris_wheel: {
    keywords: ["photo", "carnival", "londoneye"],
    char: "\uD83C\uDFA1",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  roller_coaster: {
    keywords: ["carnival", "playground", "photo", "fun"],
    char: "\uD83C\uDFA2",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  carousel_horse: {
    keywords: ["photo", "carnival"],
    char: "\uD83C\uDFA0",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  building_construction: {
    keywords: ["wip", "working", "progress"],
    char: "\uD83C\uDFD7",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  foggy: {
    keywords: ["photo", "mountain"],
    char: "\uD83C\uDF01",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  tokyo_tower: {
    keywords: ["photo", "japanese"],
    char: "\uD83D\uDDFC",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  factory: {
    keywords: ["building", "industry", "pollution", "smoke"],
    char: "\uD83C\uDFED",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  fountain: {
    keywords: ["photo", "summer", "water", "fresh"],
    char: "\u26F2",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  rice_scene: {
    keywords: ["photo", "japan", "asia", "tsukimi"],
    char: "\uD83C\uDF91",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  mountain: {
    keywords: ["photo", "nature", "environment"],
    char: "\u26F0",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  mountain_snow: {
    keywords: ["photo", "nature", "environment", "winter", "cold"],
    char: "\uD83C\uDFD4",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  mount_fuji: {
    keywords: ["photo", "mountain", "nature", "japanese"],
    char: "\uD83D\uDDFB",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  volcano: {
    keywords: ["photo", "nature", "disaster"],
    char: "\uD83C\uDF0B",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  japan: {
    keywords: ["nation", "country", "japanese", "asia"],
    char: "\uD83D\uDDFE",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  camping: {
    keywords: ["photo", "outdoors", "tent"],
    char: "\uD83C\uDFD5",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  tent: {
    keywords: ["photo", "camping", "outdoors"],
    char: "\u26FA",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  national_park: {
    keywords: ["photo", "environment", "nature"],
    char: "\uD83C\uDFDE",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  motorway: {
    keywords: ["road", "cupertino", "interstate", "highway"],
    char: "\uD83D\uDEE3",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  railway_track: {
    keywords: ["train", "transportation"],
    char: "\uD83D\uDEE4",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  sunrise: {
    keywords: ["morning", "view", "vacation", "photo"],
    char: "\uD83C\uDF05",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  sunrise_over_mountains: {
    keywords: ["view", "vacation", "photo"],
    char: "\uD83C\uDF04",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  desert: {
    keywords: ["photo", "warm", "saharah"],
    char: "\uD83C\uDFDC",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  beach_umbrella: {
    keywords: ["weather", "summer", "sunny", "sand", "mojito"],
    char: "\uD83C\uDFD6",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  desert_island: {
    keywords: ["photo", "tropical", "mojito"],
    char: "\uD83C\uDFDD",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  city_sunrise: {
    keywords: ["photo", "good morning", "dawn"],
    char: "\uD83C\uDF07",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  city_sunset: {
    keywords: ["photo", "evening", "sky", "buildings"],
    char: "\uD83C\uDF06",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  cityscape: {
    keywords: ["photo", "night life", "urban"],
    char: "\uD83C\uDFD9",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  night_with_stars: {
    keywords: ["evening", "city", "downtown"],
    char: "\uD83C\uDF03",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  bridge_at_night: {
    keywords: ["photo", "sanfrancisco"],
    char: "\uD83C\uDF09",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  milky_way: {
    keywords: ["photo", "space", "stars"],
    char: "\uD83C\uDF0C",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  stars: {
    keywords: ["night", "photo"],
    char: "\uD83C\uDF20",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  sparkler: {
    keywords: ["stars", "night", "shine"],
    char: "\uD83C\uDF87",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  fireworks: {
    keywords: ["photo", "festival", "carnival", "congratulations"],
    char: "\uD83C\uDF86",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  rainbow: {
    keywords: ["nature", "happy", "unicorn_face", "photo", "sky", "spring"],
    char: "\uD83C\uDF08",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  houses: {
    keywords: ["buildings", "photo"],
    char: "\uD83C\uDFD8",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  european_castle: {
    keywords: ["building", "royalty", "history"],
    char: "\uD83C\uDFF0",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  japanese_castle: {
    keywords: ["photo", "building"],
    char: "\uD83C\uDFEF",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  stadium: {
    keywords: ["photo", "place", "sports", "concert", "venue"],
    char: "\uD83C\uDFDF",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  statue_of_liberty: {
    keywords: ["american", "newyork"],
    char: "\uD83D\uDDFD",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  house: {
    keywords: ["building", "home"],
    char: "\uD83C\uDFE0",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  house_with_garden: {
    keywords: ["home", "plant", "nature"],
    char: "\uD83C\uDFE1",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  derelict_house: {
    keywords: ["abandon", "evict", "broken", "building"],
    char: "\uD83C\uDFDA",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  office: {
    keywords: ["building", "bureau", "work"],
    char: "\uD83C\uDFE2",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  department_store: {
    keywords: ["building", "shopping", "mall"],
    char: "\uD83C\uDFEC",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  post_office: {
    keywords: ["building", "envelope", "communication"],
    char: "\uD83C\uDFE3",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  european_post_office: {
    keywords: ["building", "email"],
    char: "\uD83C\uDFE4",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  hospital: {
    keywords: ["building", "health", "surgery", "doctor"],
    char: "\uD83C\uDFE5",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  bank: {
    keywords: ["building", "money", "sales", "cash", "business", "enterprise"],
    char: "\uD83C\uDFE6",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  hotel: {
    keywords: ["building", "accomodation", "checkin"],
    char: "\uD83C\uDFE8",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  convenience_store: {
    keywords: ["building", "shopping", "groceries"],
    char: "\uD83C\uDFEA",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  school: {
    keywords: ["building", "student", "education", "learn", "teach"],
    char: "\uD83C\uDFEB",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  love_hotel: {
    keywords: ["like", "affection", "dating"],
    char: "\uD83C\uDFE9",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  wedding: {
    keywords: ["love", "like", "affection", "couple", "marriage", "bride", "groom"],
    char: "\uD83D\uDC92",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  classical_building: {
    keywords: ["art", "culture", "history"],
    char: "\uD83C\uDFDB",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  church: {
    keywords: ["building", "religion", "christ"],
    char: "\u26EA",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  mosque: {
    keywords: ["islam", "worship", "minaret"],
    char: "\uD83D\uDD4C",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  synagogue: {
    keywords: ["judaism", "worship", "temple", "jewish"],
    char: "\uD83D\uDD4D",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  kaaba: {
    keywords: ["mecca", "mosque", "islam"],
    char: "\uD83D\uDD4B",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  shinto_shrine: {
    keywords: ["temple", "japan", "kyoto"],
    char: "\u26E9",
    fitzpatrick_scale: false,
    category: "travel_and_places"
  },
  watch: {
    keywords: ["time", "accessories"],
    char: "\u231A",
    fitzpatrick_scale: false,
    category: "objects"
  },
  iphone: {
    keywords: ["technology", "apple", "gadgets", "dial"],
    char: "\uD83D\uDCF1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  calling: {
    keywords: ["iphone", "incoming"],
    char: "\uD83D\uDCF2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  computer: {
    keywords: ["technology", "laptop", "screen", "display", "monitor"],
    char: "\uD83D\uDCBB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  keyboard: {
    keywords: ["technology", "computer", "type", "input", "text"],
    char: "\u2328",
    fitzpatrick_scale: false,
    category: "objects"
  },
  desktop_computer: {
    keywords: ["technology", "computing", "screen"],
    char: "\uD83D\uDDA5",
    fitzpatrick_scale: false,
    category: "objects"
  },
  printer: {
    keywords: ["paper", "ink"],
    char: "\uD83D\uDDA8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  computer_mouse: {
    keywords: ["click"],
    char: "\uD83D\uDDB1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  trackball: {
    keywords: ["technology", "trackpad"],
    char: "\uD83D\uDDB2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  joystick: {
    keywords: ["game", "play"],
    char: "\uD83D\uDD79",
    fitzpatrick_scale: false,
    category: "objects"
  },
  clamp: {
    keywords: ["tool"],
    char: "\uD83D\uDDDC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  minidisc: {
    keywords: ["technology", "record", "data", "disk", "90s"],
    char: "\uD83D\uDCBD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  floppy_disk: {
    keywords: ["oldschool", "technology", "save", "90s", "80s"],
    char: "\uD83D\uDCBE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  cd: {
    keywords: ["technology", "dvd", "disk", "disc", "90s"],
    char: "\uD83D\uDCBF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  dvd: {
    keywords: ["cd", "disk", "disc"],
    char: "\uD83D\uDCC0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  vhs: {
    keywords: ["record", "video", "oldschool", "90s", "80s"],
    char: "\uD83D\uDCFC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  camera: {
    keywords: ["gadgets", "photography"],
    char: "\uD83D\uDCF7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  camera_flash: {
    keywords: ["photography", "gadgets"],
    char: "\uD83D\uDCF8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  video_camera: {
    keywords: ["film", "record"],
    char: "\uD83D\uDCF9",
    fitzpatrick_scale: false,
    category: "objects"
  },
  movie_camera: {
    keywords: ["film", "record"],
    char: "\uD83C\uDFA5",
    fitzpatrick_scale: false,
    category: "objects"
  },
  film_projector: {
    keywords: ["video", "tape", "record", "movie"],
    char: "\uD83D\uDCFD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  film_strip: {
    keywords: ["movie"],
    char: "\uD83C\uDF9E",
    fitzpatrick_scale: false,
    category: "objects"
  },
  telephone_receiver: {
    keywords: ["technology", "communication", "dial"],
    char: "\uD83D\uDCDE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  phone: {
    keywords: ["technology", "communication", "dial", "telephone"],
    char: "\u260E\uFE0F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pager: {
    keywords: ["bbcall", "oldschool", "90s"],
    char: "\uD83D\uDCDF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  fax: {
    keywords: ["communication", "technology"],
    char: "\uD83D\uDCE0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  tv: {
    keywords: ["technology", "program", "oldschool", "show", "television"],
    char: "\uD83D\uDCFA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  radio: {
    keywords: ["communication", "music", "podcast", "program"],
    char: "\uD83D\uDCFB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  studio_microphone: {
    keywords: ["sing", "recording", "artist", "talkshow"],
    char: "\uD83C\uDF99",
    fitzpatrick_scale: false,
    category: "objects"
  },
  level_slider: {
    keywords: ["scale"],
    char: "\uD83C\uDF9A",
    fitzpatrick_scale: false,
    category: "objects"
  },
  control_knobs: {
    keywords: ["dial"],
    char: "\uD83C\uDF9B",
    fitzpatrick_scale: false,
    category: "objects"
  },
  compass: {
    keywords: ["magnetic", "navigation", "orienteering"],
    char: "\uD83E\uDDED",
    fitzpatrick_scale: false,
    category: "objects"
  },
  stopwatch: {
    keywords: ["time", "deadline"],
    char: "\u23F1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  timer_clock: {
    keywords: ["alarm"],
    char: "\u23F2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  alarm_clock: {
    keywords: ["time", "wake"],
    char: "\u23F0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mantelpiece_clock: {
    keywords: ["time"],
    char: "\uD83D\uDD70",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hourglass_flowing_sand: {
    keywords: ["oldschool", "time", "countdown"],
    char: "\u23F3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hourglass: {
    keywords: ["time", "clock", "oldschool", "limit", "exam", "quiz", "test"],
    char: "\u231B",
    fitzpatrick_scale: false,
    category: "objects"
  },
  satellite: {
    keywords: ["communication", "future", "radio", "space"],
    char: "\uD83D\uDCE1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  battery: {
    keywords: ["power", "energy", "sustain"],
    char: "\uD83D\uDD0B",
    fitzpatrick_scale: false,
    category: "objects"
  },
  electric_plug: {
    keywords: ["charger", "power"],
    char: "\uD83D\uDD0C",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bulb: {
    keywords: ["light", "electricity", "idea"],
    char: "\uD83D\uDCA1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  flashlight: {
    keywords: ["dark", "camping", "sight", "night"],
    char: "\uD83D\uDD26",
    fitzpatrick_scale: false,
    category: "objects"
  },
  candle: {
    keywords: ["fire", "wax"],
    char: "\uD83D\uDD6F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  fire_extinguisher: {
    keywords: ["quench"],
    char: "\uD83E\uDDEF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  wastebasket: {
    keywords: ["bin", "trash", "rubbish", "garbage", "toss"],
    char: "\uD83D\uDDD1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  oil_drum: {
    keywords: ["barrell"],
    char: "\uD83D\uDEE2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  money_with_wings: {
    keywords: ["dollar", "bills", "payment", "sale"],
    char: "\uD83D\uDCB8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  dollar: {
    keywords: ["money", "sales", "bill", "currency"],
    char: "\uD83D\uDCB5",
    fitzpatrick_scale: false,
    category: "objects"
  },
  yen: {
    keywords: ["money", "sales", "japanese", "dollar", "currency"],
    char: "\uD83D\uDCB4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  euro: {
    keywords: ["money", "sales", "dollar", "currency"],
    char: "\uD83D\uDCB6",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pound: {
    keywords: ["british", "sterling", "money", "sales", "bills", "uk", "england", "currency"],
    char: "\uD83D\uDCB7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  moneybag: {
    keywords: ["dollar", "payment", "coins", "sale"],
    char: "\uD83D\uDCB0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  credit_card: {
    keywords: ["money", "sales", "dollar", "bill", "payment", "shopping"],
    char: "\uD83D\uDCB3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  gem: {
    keywords: ["blue", "ruby", "diamond", "jewelry"],
    char: "\uD83D\uDC8E",
    fitzpatrick_scale: false,
    category: "objects"
  },
  balance_scale: {
    keywords: ["law", "fairness", "weight"],
    char: "\u2696",
    fitzpatrick_scale: false,
    category: "objects"
  },
  toolbox: {
    keywords: ["tools", "diy", "fix", "maintainer", "mechanic"],
    char: "\uD83E\uDDF0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  wrench: {
    keywords: ["tools", "diy", "ikea", "fix", "maintainer"],
    char: "\uD83D\uDD27",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hammer: {
    keywords: ["tools", "build", "create"],
    char: "\uD83D\uDD28",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hammer_and_pick: {
    keywords: ["tools", "build", "create"],
    char: "\u2692",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hammer_and_wrench: {
    keywords: ["tools", "build", "create"],
    char: "\uD83D\uDEE0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pick: {
    keywords: ["tools", "dig"],
    char: "\u26CF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  nut_and_bolt: {
    keywords: ["handy", "tools", "fix"],
    char: "\uD83D\uDD29",
    fitzpatrick_scale: false,
    category: "objects"
  },
  gear: {
    keywords: ["cog"],
    char: "\u2699",
    fitzpatrick_scale: false,
    category: "objects"
  },
  brick: {
    keywords: ["bricks"],
    char: "\uD83E\uDDF1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  chains: {
    keywords: ["lock", "arrest"],
    char: "\u26D3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  magnet: {
    keywords: ["attraction", "magnetic"],
    char: "\uD83E\uDDF2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  gun: {
    keywords: ["violence", "weapon", "pistol", "revolver"],
    char: "\uD83D\uDD2B",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bomb: {
    keywords: ["boom", "explode", "explosion", "terrorism"],
    char: "\uD83D\uDCA3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  firecracker: {
    keywords: ["dynamite", "boom", "explode", "explosion", "explosive"],
    char: "\uD83E\uDDE8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hocho: {
    keywords: ["knife", "blade", "cutlery", "kitchen", "weapon"],
    char: "\uD83D\uDD2A",
    fitzpatrick_scale: false,
    category: "objects"
  },
  dagger: {
    keywords: ["weapon"],
    char: "\uD83D\uDDE1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  crossed_swords: {
    keywords: ["weapon"],
    char: "\u2694",
    fitzpatrick_scale: false,
    category: "objects"
  },
  shield: {
    keywords: ["protection", "security"],
    char: "\uD83D\uDEE1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  smoking: {
    keywords: ["kills", "tobacco", "cigarette", "joint", "smoke"],
    char: "\uD83D\uDEAC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  skull_and_crossbones: {
    keywords: ["poison", "danger", "deadly", "scary", "death", "pirate", "evil"],
    char: "\u2620",
    fitzpatrick_scale: false,
    category: "objects"
  },
  coffin: {
    keywords: ["vampire", "dead", "die", "death", "rip", "graveyard", "cemetery", "casket", "funeral", "box"],
    char: "\u26B0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  funeral_urn: {
    keywords: ["dead", "die", "death", "rip", "ashes"],
    char: "\u26B1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  amphora: {
    keywords: ["vase", "jar"],
    char: "\uD83C\uDFFA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  crystal_ball: {
    keywords: ["disco", "party", "magic", "circus", "fortune_teller"],
    char: "\uD83D\uDD2E",
    fitzpatrick_scale: false,
    category: "objects"
  },
  prayer_beads: {
    keywords: ["dhikr", "religious"],
    char: "\uD83D\uDCFF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  nazar_amulet: {
    keywords: ["bead", "charm"],
    char: "\uD83E\uDDFF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  barber: {
    keywords: ["hair", "salon", "style"],
    char: "\uD83D\uDC88",
    fitzpatrick_scale: false,
    category: "objects"
  },
  alembic: {
    keywords: ["distilling", "science", "experiment", "chemistry"],
    char: "\u2697",
    fitzpatrick_scale: false,
    category: "objects"
  },
  telescope: {
    keywords: ["stars", "space", "zoom", "science", "astronomy"],
    char: "\uD83D\uDD2D",
    fitzpatrick_scale: false,
    category: "objects"
  },
  microscope: {
    keywords: ["laboratory", "experiment", "zoomin", "science", "study"],
    char: "\uD83D\uDD2C",
    fitzpatrick_scale: false,
    category: "objects"
  },
  hole: {
    keywords: ["embarrassing"],
    char: "\uD83D\uDD73",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pill: {
    keywords: ["health", "medicine", "doctor", "pharmacy", "drug"],
    char: "\uD83D\uDC8A",
    fitzpatrick_scale: false,
    category: "objects"
  },
  syringe: {
    keywords: ["health", "hospital", "drugs", "blood", "medicine", "needle", "doctor", "nurse"],
    char: "\uD83D\uDC89",
    fitzpatrick_scale: false,
    category: "objects"
  },
  dna: {
    keywords: ["biologist", "genetics", "life"],
    char: "\uD83E\uDDEC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  microbe: {
    keywords: ["amoeba", "bacteria", "germs"],
    char: "\uD83E\uDDA0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  petri_dish: {
    keywords: ["bacteria", "biology", "culture", "lab"],
    char: "\uD83E\uDDEB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  test_tube: {
    keywords: ["chemistry", "experiment", "lab", "science"],
    char: "\uD83E\uDDEA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  thermometer: {
    keywords: ["weather", "temperature", "hot", "cold"],
    char: "\uD83C\uDF21",
    fitzpatrick_scale: false,
    category: "objects"
  },
  broom: {
    keywords: ["cleaning", "sweeping", "witch"],
    char: "\uD83E\uDDF9",
    fitzpatrick_scale: false,
    category: "objects"
  },
  basket: {
    keywords: ["laundry"],
    char: "\uD83E\uDDFA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  toilet_paper: {
    keywords: ["roll"],
    char: "\uD83E\uDDFB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  label: {
    keywords: ["sale", "tag"],
    char: "\uD83C\uDFF7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bookmark: {
    keywords: ["favorite", "label", "save"],
    char: "\uD83D\uDD16",
    fitzpatrick_scale: false,
    category: "objects"
  },
  toilet: {
    keywords: ["restroom", "wc", "washroom", "bathroom", "potty"],
    char: "\uD83D\uDEBD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  shower: {
    keywords: ["clean", "water", "bathroom"],
    char: "\uD83D\uDEBF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bathtub: {
    keywords: ["clean", "shower", "bathroom"],
    char: "\uD83D\uDEC1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  soap: {
    keywords: ["bar", "bathing", "cleaning", "lather"],
    char: "\uD83E\uDDFC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  sponge: {
    keywords: ["absorbing", "cleaning", "porous"],
    char: "\uD83E\uDDFD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  lotion_bottle: {
    keywords: ["moisturizer", "sunscreen"],
    char: "\uD83E\uDDF4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  key: {
    keywords: ["lock", "door", "password"],
    char: "\uD83D\uDD11",
    fitzpatrick_scale: false,
    category: "objects"
  },
  old_key: {
    keywords: ["lock", "door", "password"],
    char: "\uD83D\uDDDD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  couch_and_lamp: {
    keywords: ["read", "chill"],
    char: "\uD83D\uDECB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  sleeping_bed: {
    keywords: ["bed", "rest"],
    char: "\uD83D\uDECC",
    fitzpatrick_scale: true,
    category: "objects"
  },
  bed: {
    keywords: ["sleep", "rest"],
    char: "\uD83D\uDECF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  door: {
    keywords: ["house", "entry", "exit"],
    char: "\uD83D\uDEAA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bellhop_bell: {
    keywords: ["service"],
    char: "\uD83D\uDECE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  teddy_bear: {
    keywords: ["plush", "stuffed"],
    char: "\uD83E\uDDF8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  framed_picture: {
    keywords: ["photography"],
    char: "\uD83D\uDDBC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  world_map: {
    keywords: ["location", "direction"],
    char: "\uD83D\uDDFA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  parasol_on_ground: {
    keywords: ["weather", "summer"],
    char: "\u26F1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  moyai: {
    keywords: ["rock", "easter island", "moai"],
    char: "\uD83D\uDDFF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  shopping: {
    keywords: ["mall", "buy", "purchase"],
    char: "\uD83D\uDECD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  shopping_cart: {
    keywords: ["trolley"],
    char: "\uD83D\uDED2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  balloon: {
    keywords: ["party", "celebration", "birthday", "circus"],
    char: "\uD83C\uDF88",
    fitzpatrick_scale: false,
    category: "objects"
  },
  flags: {
    keywords: ["fish", "japanese", "koinobori", "carp", "banner"],
    char: "\uD83C\uDF8F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  ribbon: {
    keywords: ["decoration", "pink", "girl", "bowtie"],
    char: "\uD83C\uDF80",
    fitzpatrick_scale: false,
    category: "objects"
  },
  gift: {
    keywords: ["present", "birthday", "christmas", "xmas"],
    char: "\uD83C\uDF81",
    fitzpatrick_scale: false,
    category: "objects"
  },
  confetti_ball: {
    keywords: ["festival", "party", "birthday", "circus"],
    char: "\uD83C\uDF8A",
    fitzpatrick_scale: false,
    category: "objects"
  },
  tada: {
    keywords: ["party", "congratulations", "birthday", "magic", "circus", "celebration"],
    char: "\uD83C\uDF89",
    fitzpatrick_scale: false,
    category: "objects"
  },
  dolls: {
    keywords: ["japanese", "toy", "kimono"],
    char: "\uD83C\uDF8E",
    fitzpatrick_scale: false,
    category: "objects"
  },
  wind_chime: {
    keywords: ["nature", "ding", "spring", "bell"],
    char: "\uD83C\uDF90",
    fitzpatrick_scale: false,
    category: "objects"
  },
  crossed_flags: {
    keywords: ["japanese", "nation", "country", "border"],
    char: "\uD83C\uDF8C",
    fitzpatrick_scale: false,
    category: "objects"
  },
  izakaya_lantern: {
    keywords: ["light", "paper", "halloween", "spooky"],
    char: "\uD83C\uDFEE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  red_envelope: {
    keywords: ["gift"],
    char: "\uD83E\uDDE7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  email: {
    keywords: ["letter", "postal", "inbox", "communication"],
    char: "\u2709\uFE0F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  envelope_with_arrow: {
    keywords: ["email", "communication"],
    char: "\uD83D\uDCE9",
    fitzpatrick_scale: false,
    category: "objects"
  },
  incoming_envelope: {
    keywords: ["email", "inbox"],
    char: "\uD83D\uDCE8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  "e-mail": {
    keywords: ["communication", "inbox"],
    char: "\uD83D\uDCE7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  love_letter: {
    keywords: ["email", "like", "affection", "envelope", "valentines"],
    char: "\uD83D\uDC8C",
    fitzpatrick_scale: false,
    category: "objects"
  },
  postbox: {
    keywords: ["email", "letter", "envelope"],
    char: "\uD83D\uDCEE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mailbox_closed: {
    keywords: ["email", "communication", "inbox"],
    char: "\uD83D\uDCEA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mailbox: {
    keywords: ["email", "inbox", "communication"],
    char: "\uD83D\uDCEB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mailbox_with_mail: {
    keywords: ["email", "inbox", "communication"],
    char: "\uD83D\uDCEC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mailbox_with_no_mail: {
    keywords: ["email", "inbox"],
    char: "\uD83D\uDCED",
    fitzpatrick_scale: false,
    category: "objects"
  },
  package: {
    keywords: ["mail", "gift", "cardboard", "box", "moving"],
    char: "\uD83D\uDCE6",
    fitzpatrick_scale: false,
    category: "objects"
  },
  postal_horn: {
    keywords: ["instrument", "music"],
    char: "\uD83D\uDCEF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  inbox_tray: {
    keywords: ["email", "documents"],
    char: "\uD83D\uDCE5",
    fitzpatrick_scale: false,
    category: "objects"
  },
  outbox_tray: {
    keywords: ["inbox", "email"],
    char: "\uD83D\uDCE4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  scroll: {
    keywords: ["documents", "ancient", "history", "paper"],
    char: "\uD83D\uDCDC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  page_with_curl: {
    keywords: ["documents", "office", "paper"],
    char: "\uD83D\uDCC3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bookmark_tabs: {
    keywords: ["favorite", "save", "order", "tidy"],
    char: "\uD83D\uDCD1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  receipt: {
    keywords: ["accounting", "expenses"],
    char: "\uD83E\uDDFE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  bar_chart: {
    keywords: ["graph", "presentation", "stats"],
    char: "\uD83D\uDCCA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  chart_with_upwards_trend: {
    keywords: ["graph", "presentation", "stats", "recovery", "business", "economics", "money", "sales", "good", "success"],
    char: "\uD83D\uDCC8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  chart_with_downwards_trend: {
    keywords: ["graph", "presentation", "stats", "recession", "business", "economics", "money", "sales", "bad", "failure"],
    char: "\uD83D\uDCC9",
    fitzpatrick_scale: false,
    category: "objects"
  },
  page_facing_up: {
    keywords: ["documents", "office", "paper", "information"],
    char: "\uD83D\uDCC4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  date: {
    keywords: ["calendar", "schedule"],
    char: "\uD83D\uDCC5",
    fitzpatrick_scale: false,
    category: "objects"
  },
  calendar: {
    keywords: ["schedule", "date", "planning"],
    char: "\uD83D\uDCC6",
    fitzpatrick_scale: false,
    category: "objects"
  },
  spiral_calendar: {
    keywords: ["date", "schedule", "planning"],
    char: "\uD83D\uDDD3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  card_index: {
    keywords: ["business", "stationery"],
    char: "\uD83D\uDCC7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  card_file_box: {
    keywords: ["business", "stationery"],
    char: "\uD83D\uDDC3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  ballot_box: {
    keywords: ["election", "vote"],
    char: "\uD83D\uDDF3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  file_cabinet: {
    keywords: ["filing", "organizing"],
    char: "\uD83D\uDDC4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  clipboard: {
    keywords: ["stationery", "documents"],
    char: "\uD83D\uDCCB",
    fitzpatrick_scale: false,
    category: "objects"
  },
  spiral_notepad: {
    keywords: ["memo", "stationery"],
    char: "\uD83D\uDDD2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  file_folder: {
    keywords: ["documents", "business", "office"],
    char: "\uD83D\uDCC1",
    fitzpatrick_scale: false,
    category: "objects"
  },
  open_file_folder: {
    keywords: ["documents", "load"],
    char: "\uD83D\uDCC2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  card_index_dividers: {
    keywords: ["organizing", "business", "stationery"],
    char: "\uD83D\uDDC2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  newspaper_roll: {
    keywords: ["press", "headline"],
    char: "\uD83D\uDDDE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  newspaper: {
    keywords: ["press", "headline"],
    char: "\uD83D\uDCF0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  notebook: {
    keywords: ["stationery", "record", "notes", "paper", "study"],
    char: "\uD83D\uDCD3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  closed_book: {
    keywords: ["read", "library", "knowledge", "textbook", "learn"],
    char: "\uD83D\uDCD5",
    fitzpatrick_scale: false,
    category: "objects"
  },
  green_book: {
    keywords: ["read", "library", "knowledge", "study"],
    char: "\uD83D\uDCD7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  blue_book: {
    keywords: ["read", "library", "knowledge", "learn", "study"],
    char: "\uD83D\uDCD8",
    fitzpatrick_scale: false,
    category: "objects"
  },
  orange_book: {
    keywords: ["read", "library", "knowledge", "textbook", "study"],
    char: "\uD83D\uDCD9",
    fitzpatrick_scale: false,
    category: "objects"
  },
  notebook_with_decorative_cover: {
    keywords: ["classroom", "notes", "record", "paper", "study"],
    char: "\uD83D\uDCD4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  ledger: {
    keywords: ["notes", "paper"],
    char: "\uD83D\uDCD2",
    fitzpatrick_scale: false,
    category: "objects"
  },
  books: {
    keywords: ["literature", "library", "study"],
    char: "\uD83D\uDCDA",
    fitzpatrick_scale: false,
    category: "objects"
  },
  open_book: {
    keywords: ["book", "read", "library", "knowledge", "literature", "learn", "study"],
    char: "\uD83D\uDCD6",
    fitzpatrick_scale: false,
    category: "objects"
  },
  safety_pin: {
    keywords: ["diaper"],
    char: "\uD83E\uDDF7",
    fitzpatrick_scale: false,
    category: "objects"
  },
  link: {
    keywords: ["rings", "url"],
    char: "\uD83D\uDD17",
    fitzpatrick_scale: false,
    category: "objects"
  },
  paperclip: {
    keywords: ["documents", "stationery"],
    char: "\uD83D\uDCCE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  paperclips: {
    keywords: ["documents", "stationery"],
    char: "\uD83D\uDD87",
    fitzpatrick_scale: false,
    category: "objects"
  },
  scissors: {
    keywords: ["stationery", "cut"],
    char: "\u2702\uFE0F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  triangular_ruler: {
    keywords: ["stationery", "math", "architect", "sketch"],
    char: "\uD83D\uDCD0",
    fitzpatrick_scale: false,
    category: "objects"
  },
  straight_ruler: {
    keywords: ["stationery", "calculate", "length", "math", "school", "drawing", "architect", "sketch"],
    char: "\uD83D\uDCCF",
    fitzpatrick_scale: false,
    category: "objects"
  },
  abacus: {
    keywords: ["calculation"],
    char: "\uD83E\uDDEE",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pushpin: {
    keywords: ["stationery", "mark", "here"],
    char: "\uD83D\uDCCC",
    fitzpatrick_scale: false,
    category: "objects"
  },
  round_pushpin: {
    keywords: ["stationery", "location", "map", "here"],
    char: "\uD83D\uDCCD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  triangular_flag_on_post: {
    keywords: ["mark", "milestone", "place"],
    char: "\uD83D\uDEA9",
    fitzpatrick_scale: false,
    category: "objects"
  },
  white_flag: {
    keywords: ["losing", "loser", "lost", "surrender", "give up", "fail"],
    char: "\uD83C\uDFF3",
    fitzpatrick_scale: false,
    category: "objects"
  },
  black_flag: {
    keywords: ["pirate"],
    char: "\uD83C\uDFF4",
    fitzpatrick_scale: false,
    category: "objects"
  },
  rainbow_flag: {
    keywords: ["flag", "rainbow", "pride", "gay", "lgbt", "glbt", "queer", "homosexual", "lesbian", "bisexual", "transgender"],
    char: "\uD83C\uDFF3\uFE0F\u200D\uD83C\uDF08",
    fitzpatrick_scale: false,
    category: "objects"
  },
  closed_lock_with_key: {
    keywords: ["security", "privacy"],
    char: "\uD83D\uDD10",
    fitzpatrick_scale: false,
    category: "objects"
  },
  lock: {
    keywords: ["security", "password", "padlock"],
    char: "\uD83D\uDD12",
    fitzpatrick_scale: false,
    category: "objects"
  },
  unlock: {
    keywords: ["privacy", "security"],
    char: "\uD83D\uDD13",
    fitzpatrick_scale: false,
    category: "objects"
  },
  lock_with_ink_pen: {
    keywords: ["security", "secret"],
    char: "\uD83D\uDD0F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pen: {
    keywords: ["stationery", "writing", "write"],
    char: "\uD83D\uDD8A",
    fitzpatrick_scale: false,
    category: "objects"
  },
  fountain_pen: {
    keywords: ["stationery", "writing", "write"],
    char: "\uD83D\uDD8B",
    fitzpatrick_scale: false,
    category: "objects"
  },
  black_nib: {
    keywords: ["pen", "stationery", "writing", "write"],
    char: "\u2712\uFE0F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  memo: {
    keywords: ["write", "documents", "stationery", "pencil", "paper", "writing", "legal", "exam", "quiz", "test", "study", "compose"],
    char: "\uD83D\uDCDD",
    fitzpatrick_scale: false,
    category: "objects"
  },
  pencil2: {
    keywords: ["stationery", "write", "paper", "writing", "school", "study"],
    char: "\u270F\uFE0F",
    fitzpatrick_scale: false,
    category: "objects"
  },
  crayon: {
    keywords: ["drawing", "creativity"],
    char: "\uD83D\uDD8D",
    fitzpatrick_scale: false,
    category: "objects"
  },
  paintbrush: {
    keywords: ["drawing", "creativity", "art"],
    char: "\uD83D\uDD8C",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mag: {
    keywords: ["search", "zoom", "find", "detective"],
    char: "\uD83D\uDD0D",
    fitzpatrick_scale: false,
    category: "objects"
  },
  mag_right: {
    keywords: ["search", "zoom", "find", "detective"],
    char: "\uD83D\uDD0E",
    fitzpatrick_scale: false,
    category: "objects"
  },
  heart: {
    keywords: ["love", "like", "valentines"],
    char: "\u2764\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  orange_heart: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83E\uDDE1",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  yellow_heart: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83D\uDC9B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  green_heart: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83D\uDC9A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  blue_heart: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83D\uDC99",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  purple_heart: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83D\uDC9C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_heart: {
    keywords: ["evil"],
    char: "\uD83D\uDDA4",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  broken_heart: {
    keywords: ["sad", "sorry", "break", "heart", "heartbreak"],
    char: "\uD83D\uDC94",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_heart_exclamation: {
    keywords: ["decoration", "love"],
    char: "\u2763",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  two_hearts: {
    keywords: ["love", "like", "affection", "valentines", "heart"],
    char: "\uD83D\uDC95",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  revolving_hearts: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83D\uDC9E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heartbeat: {
    keywords: ["love", "like", "affection", "valentines", "pink", "heart"],
    char: "\uD83D\uDC93",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heartpulse: {
    keywords: ["like", "love", "affection", "valentines", "pink"],
    char: "\uD83D\uDC97",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  sparkling_heart: {
    keywords: ["love", "like", "affection", "valentines"],
    char: "\uD83D\uDC96",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  cupid: {
    keywords: ["love", "like", "heart", "affection", "valentines"],
    char: "\uD83D\uDC98",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  gift_heart: {
    keywords: ["love", "valentines"],
    char: "\uD83D\uDC9D",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heart_decoration: {
    keywords: ["purple-square", "love", "like"],
    char: "\uD83D\uDC9F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  peace_symbol: {
    keywords: ["hippie"],
    char: "\u262E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  latin_cross: {
    keywords: ["christianity"],
    char: "\u271D",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  star_and_crescent: {
    keywords: ["islam"],
    char: "\u262A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  om: {
    keywords: ["hinduism", "buddhism", "sikhism", "jainism"],
    char: "\uD83D\uDD49",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  wheel_of_dharma: {
    keywords: ["hinduism", "buddhism", "sikhism", "jainism"],
    char: "\u2638",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  star_of_david: {
    keywords: ["judaism"],
    char: "\u2721",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  six_pointed_star: {
    keywords: ["purple-square", "religion", "jewish", "hexagram"],
    char: "\uD83D\uDD2F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  menorah: {
    keywords: ["hanukkah", "candles", "jewish"],
    char: "\uD83D\uDD4E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  yin_yang: {
    keywords: ["balance"],
    char: "\u262F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  orthodox_cross: {
    keywords: ["suppedaneum", "religion"],
    char: "\u2626",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  place_of_worship: {
    keywords: ["religion", "church", "temple", "prayer"],
    char: "\uD83D\uDED0",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  ophiuchus: {
    keywords: ["sign", "purple-square", "constellation", "astrology"],
    char: "\u26CE",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  aries: {
    keywords: ["sign", "purple-square", "zodiac", "astrology"],
    char: "\u2648",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  taurus: {
    keywords: ["purple-square", "sign", "zodiac", "astrology"],
    char: "\u2649",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  gemini: {
    keywords: ["sign", "zodiac", "purple-square", "astrology"],
    char: "\u264A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  cancer: {
    keywords: ["sign", "zodiac", "purple-square", "astrology"],
    char: "\u264B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  leo: {
    keywords: ["sign", "purple-square", "zodiac", "astrology"],
    char: "\u264C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  virgo: {
    keywords: ["sign", "zodiac", "purple-square", "astrology"],
    char: "\u264D",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  libra: {
    keywords: ["sign", "purple-square", "zodiac", "astrology"],
    char: "\u264E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  scorpius: {
    keywords: ["sign", "zodiac", "purple-square", "astrology", "scorpio"],
    char: "\u264F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  sagittarius: {
    keywords: ["sign", "zodiac", "purple-square", "astrology"],
    char: "\u2650",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  capricorn: {
    keywords: ["sign", "zodiac", "purple-square", "astrology"],
    char: "\u2651",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  aquarius: {
    keywords: ["sign", "purple-square", "zodiac", "astrology"],
    char: "\u2652",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  pisces: {
    keywords: ["purple-square", "sign", "zodiac", "astrology"],
    char: "\u2653",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  id: {
    keywords: ["purple-square", "words"],
    char: "\uD83C\uDD94",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  atom_symbol: {
    keywords: ["science", "physics", "chemistry"],
    char: "\u269B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u7a7a: {
    keywords: ["kanji", "japanese", "chinese", "empty", "sky", "blue-square"],
    char: "\uD83C\uDE33",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u5272: {
    keywords: ["cut", "divide", "chinese", "kanji", "pink-square"],
    char: "\uD83C\uDE39",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  radioactive: {
    keywords: ["nuclear", "danger"],
    char: "\u2622",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  biohazard: {
    keywords: ["danger"],
    char: "\u2623",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  mobile_phone_off: {
    keywords: ["mute", "orange-square", "silence", "quiet"],
    char: "\uD83D\uDCF4",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  vibration_mode: {
    keywords: ["orange-square", "phone"],
    char: "\uD83D\uDCF3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u6709: {
    keywords: ["orange-square", "chinese", "have", "kanji"],
    char: "\uD83C\uDE36",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u7121: {
    keywords: ["nothing", "chinese", "kanji", "japanese", "orange-square"],
    char: "\uD83C\uDE1A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u7533: {
    keywords: ["chinese", "japanese", "kanji", "orange-square"],
    char: "\uD83C\uDE38",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u55b6: {
    keywords: ["japanese", "opening hours", "orange-square"],
    char: "\uD83C\uDE3A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u6708: {
    keywords: ["chinese", "month", "moon", "japanese", "orange-square", "kanji"],
    char: "\uD83C\uDE37\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  eight_pointed_black_star: {
    keywords: ["orange-square", "shape", "polygon"],
    char: "\u2734\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  vs: {
    keywords: ["words", "orange-square"],
    char: "\uD83C\uDD9A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  accept: {
    keywords: ["ok", "good", "chinese", "kanji", "agree", "yes", "orange-circle"],
    char: "\uD83C\uDE51",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_flower: {
    keywords: ["japanese", "spring"],
    char: "\uD83D\uDCAE",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  ideograph_advantage: {
    keywords: ["chinese", "kanji", "obtain", "get", "circle"],
    char: "\uD83C\uDE50",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  secret: {
    keywords: ["privacy", "chinese", "sshh", "kanji", "red-circle"],
    char: "\u3299\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  congratulations: {
    keywords: ["chinese", "kanji", "japanese", "red-circle"],
    char: "\u3297\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u5408: {
    keywords: ["japanese", "chinese", "join", "kanji", "red-square"],
    char: "\uD83C\uDE34",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u6e80: {
    keywords: ["full", "chinese", "japanese", "red-square", "kanji"],
    char: "\uD83C\uDE35",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u7981: {
    keywords: ["kanji", "japanese", "chinese", "forbidden", "limit", "restricted", "red-square"],
    char: "\uD83C\uDE32",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  a: {
    keywords: ["red-square", "alphabet", "letter"],
    char: "\uD83C\uDD70\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  b: {
    keywords: ["red-square", "alphabet", "letter"],
    char: "\uD83C\uDD71\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  ab: {
    keywords: ["red-square", "alphabet"],
    char: "\uD83C\uDD8E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  cl: {
    keywords: ["alphabet", "words", "red-square"],
    char: "\uD83C\uDD91",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  o2: {
    keywords: ["alphabet", "red-square", "letter"],
    char: "\uD83C\uDD7E\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  sos: {
    keywords: ["help", "red-square", "words", "emergency", "911"],
    char: "\uD83C\uDD98",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_entry: {
    keywords: ["limit", "security", "privacy", "bad", "denied", "stop", "circle"],
    char: "\u26D4",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  name_badge: {
    keywords: ["fire", "forbid"],
    char: "\uD83D\uDCDB",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_entry_sign: {
    keywords: ["forbid", "stop", "limit", "denied", "disallow", "circle"],
    char: "\uD83D\uDEAB",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  x: {
    keywords: ["no", "delete", "remove", "cancel", "red"],
    char: "\u274C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  o: {
    keywords: ["circle", "round"],
    char: "\u2B55",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  stop_sign: {
    keywords: ["stop"],
    char: "\uD83D\uDED1",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  anger: {
    keywords: ["angry", "mad"],
    char: "\uD83D\uDCA2",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  hotsprings: {
    keywords: ["bath", "warm", "relax"],
    char: "\u2668\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_pedestrians: {
    keywords: ["rules", "crossing", "walking", "circle"],
    char: "\uD83D\uDEB7",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  do_not_litter: {
    keywords: ["trash", "bin", "garbage", "circle"],
    char: "\uD83D\uDEAF",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_bicycles: {
    keywords: ["cyclist", "prohibited", "circle"],
    char: "\uD83D\uDEB3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  "non-potable_water": {
    keywords: ["drink", "faucet", "tap", "circle"],
    char: "\uD83D\uDEB1",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  underage: {
    keywords: ["18", "drink", "pub", "night", "minor", "circle"],
    char: "\uD83D\uDD1E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_mobile_phones: {
    keywords: ["iphone", "mute", "circle"],
    char: "\uD83D\uDCF5",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  exclamation: {
    keywords: ["heavy_exclamation_mark", "danger", "surprise", "punctuation", "wow", "warning"],
    char: "\u2757",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  grey_exclamation: {
    keywords: ["surprise", "punctuation", "gray", "wow", "warning"],
    char: "\u2755",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  question: {
    keywords: ["doubt", "confused"],
    char: "\u2753",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  grey_question: {
    keywords: ["doubts", "gray", "huh", "confused"],
    char: "\u2754",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  bangbang: {
    keywords: ["exclamation", "surprise"],
    char: "\u203C\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  interrobang: {
    keywords: ["wat", "punctuation", "surprise"],
    char: "\u2049\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  100: {
    keywords: ["score", "perfect", "numbers", "century", "exam", "quiz", "test", "pass", "hundred"],
    char: "\uD83D\uDCAF",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  low_brightness: {
    keywords: ["sun", "afternoon", "warm", "summer"],
    char: "\uD83D\uDD05",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  high_brightness: {
    keywords: ["sun", "light"],
    char: "\uD83D\uDD06",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  trident: {
    keywords: ["weapon", "spear"],
    char: "\uD83D\uDD31",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  fleur_de_lis: {
    keywords: ["decorative", "scout"],
    char: "\u269C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  part_alternation_mark: {
    keywords: ["graph", "presentation", "stats", "business", "economics", "bad"],
    char: "\u303D\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  warning: {
    keywords: ["exclamation", "wip", "alert", "error", "problem", "issue"],
    char: "\u26A0\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  children_crossing: {
    keywords: ["school", "warning", "danger", "sign", "driving", "yellow-diamond"],
    char: "\uD83D\uDEB8",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  beginner: {
    keywords: ["badge", "shield"],
    char: "\uD83D\uDD30",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  recycle: {
    keywords: ["arrow", "environment", "garbage", "trash"],
    char: "\u267B\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  u6307: {
    keywords: ["chinese", "point", "green-square", "kanji"],
    char: "\uD83C\uDE2F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  chart: {
    keywords: ["green-square", "graph", "presentation", "stats"],
    char: "\uD83D\uDCB9",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  sparkle: {
    keywords: ["stars", "green-square", "awesome", "good", "fireworks"],
    char: "\u2747\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  eight_spoked_asterisk: {
    keywords: ["star", "sparkle", "green-square"],
    char: "\u2733\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  negative_squared_cross_mark: {
    keywords: ["x", "green-square", "no", "deny"],
    char: "\u274E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_check_mark: {
    keywords: ["green-square", "ok", "agree", "vote", "election", "answer", "tick"],
    char: "\u2705",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  diamond_shape_with_a_dot_inside: {
    keywords: ["jewel", "blue", "gem", "crystal", "fancy"],
    char: "\uD83D\uDCA0",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  cyclone: {
    keywords: ["weather", "swirl", "blue", "cloud", "vortex", "spiral", "whirlpool", "spin", "tornado", "hurricane", "typhoon"],
    char: "\uD83C\uDF00",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  loop: {
    keywords: ["tape", "cassette"],
    char: "\u27BF",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  globe_with_meridians: {
    keywords: ["earth", "international", "world", "internet", "interweb", "i18n"],
    char: "\uD83C\uDF10",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  m: {
    keywords: ["alphabet", "blue-circle", "letter"],
    char: "\u24C2\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  atm: {
    keywords: ["money", "sales", "cash", "blue-square", "payment", "bank"],
    char: "\uD83C\uDFE7",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  sa: {
    keywords: ["japanese", "blue-square", "katakana"],
    char: "\uD83C\uDE02\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  passport_control: {
    keywords: ["custom", "blue-square"],
    char: "\uD83D\uDEC2",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  customs: {
    keywords: ["passport", "border", "blue-square"],
    char: "\uD83D\uDEC3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  baggage_claim: {
    keywords: ["blue-square", "airport", "transport"],
    char: "\uD83D\uDEC4",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  left_luggage: {
    keywords: ["blue-square", "travel"],
    char: "\uD83D\uDEC5",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  wheelchair: {
    keywords: ["blue-square", "disabled", "a11y", "accessibility"],
    char: "\u267F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_smoking: {
    keywords: ["cigarette", "blue-square", "smell", "smoke"],
    char: "\uD83D\uDEAD",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  wc: {
    keywords: ["toilet", "restroom", "blue-square"],
    char: "\uD83D\uDEBE",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  parking: {
    keywords: ["cars", "blue-square", "alphabet", "letter"],
    char: "\uD83C\uDD7F\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  potable_water: {
    keywords: ["blue-square", "liquid", "restroom", "cleaning", "faucet"],
    char: "\uD83D\uDEB0",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  mens: {
    keywords: ["toilet", "restroom", "wc", "blue-square", "gender", "male"],
    char: "\uD83D\uDEB9",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  womens: {
    keywords: ["purple-square", "woman", "female", "toilet", "loo", "restroom", "gender"],
    char: "\uD83D\uDEBA",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  baby_symbol: {
    keywords: ["orange-square", "child"],
    char: "\uD83D\uDEBC",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  restroom: {
    keywords: ["blue-square", "toilet", "refresh", "wc", "gender"],
    char: "\uD83D\uDEBB",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  put_litter_in_its_place: {
    keywords: ["blue-square", "sign", "human", "info"],
    char: "\uD83D\uDEAE",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  cinema: {
    keywords: ["blue-square", "record", "film", "movie", "curtain", "stage", "theater"],
    char: "\uD83C\uDFA6",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  signal_strength: {
    keywords: ["blue-square", "reception", "phone", "internet", "connection", "wifi", "bluetooth", "bars"],
    char: "\uD83D\uDCF6",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  koko: {
    keywords: ["blue-square", "here", "katakana", "japanese", "destination"],
    char: "\uD83C\uDE01",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  ng: {
    keywords: ["blue-square", "words", "shape", "icon"],
    char: "\uD83C\uDD96",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  ok: {
    keywords: ["good", "agree", "yes", "blue-square"],
    char: "\uD83C\uDD97",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  up: {
    keywords: ["blue-square", "above", "high"],
    char: "\uD83C\uDD99",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  cool: {
    keywords: ["words", "blue-square"],
    char: "\uD83C\uDD92",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  new: {
    keywords: ["blue-square", "words", "start"],
    char: "\uD83C\uDD95",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  free: {
    keywords: ["blue-square", "words"],
    char: "\uD83C\uDD93",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  zero: {
    keywords: ["0", "numbers", "blue-square", "null"],
    char: "0\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  one: {
    keywords: ["blue-square", "numbers", "1"],
    char: "1\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  two: {
    keywords: ["numbers", "2", "prime", "blue-square"],
    char: "2\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  three: {
    keywords: ["3", "numbers", "prime", "blue-square"],
    char: "3\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  four: {
    keywords: ["4", "numbers", "blue-square"],
    char: "4\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  five: {
    keywords: ["5", "numbers", "blue-square", "prime"],
    char: "5\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  six: {
    keywords: ["6", "numbers", "blue-square"],
    char: "6\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  seven: {
    keywords: ["7", "numbers", "blue-square", "prime"],
    char: "7\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  eight: {
    keywords: ["8", "blue-square", "numbers"],
    char: "8\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  nine: {
    keywords: ["blue-square", "numbers", "9"],
    char: "9\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  keycap_ten: {
    keywords: ["numbers", "10", "blue-square"],
    char: "\uD83D\uDD1F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  asterisk: {
    keywords: ["star", "keycap"],
    char: "*\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  1234: {
    keywords: ["numbers", "blue-square"],
    char: "\uD83D\uDD22",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  eject_button: {
    keywords: ["blue-square"],
    char: "\u23CF\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_forward: {
    keywords: ["blue-square", "right", "direction", "play"],
    char: "\u25B6\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  pause_button: {
    keywords: ["pause", "blue-square"],
    char: "\u23F8",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  next_track_button: {
    keywords: ["forward", "next", "blue-square"],
    char: "\u23ED",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  stop_button: {
    keywords: ["blue-square"],
    char: "\u23F9",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  record_button: {
    keywords: ["blue-square"],
    char: "\u23FA",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  play_or_pause_button: {
    keywords: ["blue-square", "play", "pause"],
    char: "\u23EF",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  previous_track_button: {
    keywords: ["backward"],
    char: "\u23EE",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  fast_forward: {
    keywords: ["blue-square", "play", "speed", "continue"],
    char: "\u23E9",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  rewind: {
    keywords: ["play", "blue-square"],
    char: "\u23EA",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  twisted_rightwards_arrows: {
    keywords: ["blue-square", "shuffle", "music", "random"],
    char: "\uD83D\uDD00",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  repeat: {
    keywords: ["loop", "record"],
    char: "\uD83D\uDD01",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  repeat_one: {
    keywords: ["blue-square", "loop"],
    char: "\uD83D\uDD02",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_backward: {
    keywords: ["blue-square", "left", "direction"],
    char: "\u25C0\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_up_small: {
    keywords: ["blue-square", "triangle", "direction", "point", "forward", "top"],
    char: "\uD83D\uDD3C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_down_small: {
    keywords: ["blue-square", "direction", "bottom"],
    char: "\uD83D\uDD3D",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_double_up: {
    keywords: ["blue-square", "direction", "top"],
    char: "\u23EB",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_double_down: {
    keywords: ["blue-square", "direction", "bottom"],
    char: "\u23EC",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_right: {
    keywords: ["blue-square", "next"],
    char: "\u27A1\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_left: {
    keywords: ["blue-square", "previous", "back"],
    char: "\u2B05\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_up: {
    keywords: ["blue-square", "continue", "top", "direction"],
    char: "\u2B06\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_down: {
    keywords: ["blue-square", "direction", "bottom"],
    char: "\u2B07\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_upper_right: {
    keywords: ["blue-square", "point", "direction", "diagonal", "northeast"],
    char: "\u2197\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_lower_right: {
    keywords: ["blue-square", "direction", "diagonal", "southeast"],
    char: "\u2198\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_lower_left: {
    keywords: ["blue-square", "direction", "diagonal", "southwest"],
    char: "\u2199\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_upper_left: {
    keywords: ["blue-square", "point", "direction", "diagonal", "northwest"],
    char: "\u2196\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_up_down: {
    keywords: ["blue-square", "direction", "way", "vertical"],
    char: "\u2195\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  left_right_arrow: {
    keywords: ["shape", "direction", "horizontal", "sideways"],
    char: "\u2194\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrows_counterclockwise: {
    keywords: ["blue-square", "sync", "cycle"],
    char: "\uD83D\uDD04",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_right_hook: {
    keywords: ["blue-square", "return", "rotate", "direction"],
    char: "\u21AA\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  leftwards_arrow_with_hook: {
    keywords: ["back", "return", "blue-square", "undo", "enter"],
    char: "\u21A9\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_heading_up: {
    keywords: ["blue-square", "direction", "top"],
    char: "\u2934\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrow_heading_down: {
    keywords: ["blue-square", "direction", "bottom"],
    char: "\u2935\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  hash: {
    keywords: ["symbol", "blue-square", "twitter"],
    char: "#\uFE0F\u20E3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  information_source: {
    keywords: ["blue-square", "alphabet", "letter"],
    char: "\u2139\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  abc: {
    keywords: ["blue-square", "alphabet"],
    char: "\uD83D\uDD24",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  abcd: {
    keywords: ["blue-square", "alphabet"],
    char: "\uD83D\uDD21",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  capital_abcd: {
    keywords: ["alphabet", "words", "blue-square"],
    char: "\uD83D\uDD20",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  symbols: {
    keywords: ["blue-square", "music", "note", "ampersand", "percent", "glyphs", "characters"],
    char: "\uD83D\uDD23",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  musical_note: {
    keywords: ["score", "tone", "sound"],
    char: "\uD83C\uDFB5",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  notes: {
    keywords: ["music", "score"],
    char: "\uD83C\uDFB6",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  wavy_dash: {
    keywords: ["draw", "line", "moustache", "mustache", "squiggle", "scribble"],
    char: "\u3030\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  curly_loop: {
    keywords: ["scribble", "draw", "shape", "squiggle"],
    char: "\u27B0",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_check_mark: {
    keywords: ["ok", "nike", "answer", "yes", "tick"],
    char: "\u2714\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  arrows_clockwise: {
    keywords: ["sync", "cycle", "round", "repeat"],
    char: "\uD83D\uDD03",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_plus_sign: {
    keywords: ["math", "calculation", "addition", "more", "increase"],
    char: "\u2795",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_minus_sign: {
    keywords: ["math", "calculation", "subtract", "less"],
    char: "\u2796",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_division_sign: {
    keywords: ["divide", "math", "calculation"],
    char: "\u2797",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_multiplication_x: {
    keywords: ["math", "calculation"],
    char: "\u2716\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  infinity: {
    keywords: ["forever"],
    char: "\u267E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  heavy_dollar_sign: {
    keywords: ["money", "sales", "payment", "currency", "buck"],
    char: "\uD83D\uDCB2",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  currency_exchange: {
    keywords: ["money", "sales", "dollar", "travel"],
    char: "\uD83D\uDCB1",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  copyright: {
    keywords: ["ip", "license", "circle", "law", "legal"],
    char: "\xA9\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  registered: {
    keywords: ["alphabet", "circle"],
    char: "\xAE\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  tm: {
    keywords: ["trademark", "brand", "law", "legal"],
    char: "\u2122\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  end: {
    keywords: ["words", "arrow"],
    char: "\uD83D\uDD1A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  back: {
    keywords: ["arrow", "words", "return"],
    char: "\uD83D\uDD19",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  on: {
    keywords: ["arrow", "words"],
    char: "\uD83D\uDD1B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  top: {
    keywords: ["words", "blue-square"],
    char: "\uD83D\uDD1D",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  soon: {
    keywords: ["arrow", "words"],
    char: "\uD83D\uDD1C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  ballot_box_with_check: {
    keywords: ["ok", "agree", "confirm", "black-square", "vote", "election", "yes", "tick"],
    char: "\u2611\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  radio_button: {
    keywords: ["input", "old", "music", "circle"],
    char: "\uD83D\uDD18",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_circle: {
    keywords: ["shape", "round"],
    char: "\u26AA",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_circle: {
    keywords: ["shape", "button", "round"],
    char: "\u26AB",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  red_circle: {
    keywords: ["shape", "error", "danger"],
    char: "\uD83D\uDD34",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  large_blue_circle: {
    keywords: ["shape", "icon", "button"],
    char: "\uD83D\uDD35",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  small_orange_diamond: {
    keywords: ["shape", "jewel", "gem"],
    char: "\uD83D\uDD38",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  small_blue_diamond: {
    keywords: ["shape", "jewel", "gem"],
    char: "\uD83D\uDD39",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  large_orange_diamond: {
    keywords: ["shape", "jewel", "gem"],
    char: "\uD83D\uDD36",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  large_blue_diamond: {
    keywords: ["shape", "jewel", "gem"],
    char: "\uD83D\uDD37",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  small_red_triangle: {
    keywords: ["shape", "direction", "up", "top"],
    char: "\uD83D\uDD3A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_small_square: {
    keywords: ["shape", "icon"],
    char: "\u25AA\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_small_square: {
    keywords: ["shape", "icon"],
    char: "\u25AB\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_large_square: {
    keywords: ["shape", "icon", "button"],
    char: "\u2B1B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_large_square: {
    keywords: ["shape", "icon", "stone", "button"],
    char: "\u2B1C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  small_red_triangle_down: {
    keywords: ["shape", "direction", "bottom"],
    char: "\uD83D\uDD3B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_medium_square: {
    keywords: ["shape", "button", "icon"],
    char: "\u25FC\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_medium_square: {
    keywords: ["shape", "stone", "icon"],
    char: "\u25FB\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_medium_small_square: {
    keywords: ["icon", "shape", "button"],
    char: "\u25FE",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_medium_small_square: {
    keywords: ["shape", "stone", "icon", "button"],
    char: "\u25FD",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_square_button: {
    keywords: ["shape", "input", "frame"],
    char: "\uD83D\uDD32",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  white_square_button: {
    keywords: ["shape", "input"],
    char: "\uD83D\uDD33",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  speaker: {
    keywords: ["sound", "volume", "silence", "broadcast"],
    char: "\uD83D\uDD08",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  sound: {
    keywords: ["volume", "speaker", "broadcast"],
    char: "\uD83D\uDD09",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  loud_sound: {
    keywords: ["volume", "noise", "noisy", "speaker", "broadcast"],
    char: "\uD83D\uDD0A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  mute: {
    keywords: ["sound", "volume", "silence", "quiet"],
    char: "\uD83D\uDD07",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  mega: {
    keywords: ["sound", "speaker", "volume"],
    char: "\uD83D\uDCE3",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  loudspeaker: {
    keywords: ["volume", "sound"],
    char: "\uD83D\uDCE2",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  bell: {
    keywords: ["sound", "notification", "christmas", "xmas", "chime"],
    char: "\uD83D\uDD14",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  no_bell: {
    keywords: ["sound", "volume", "mute", "quiet", "silent"],
    char: "\uD83D\uDD15",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  black_joker: {
    keywords: ["poker", "cards", "game", "play", "magic"],
    char: "\uD83C\uDCCF",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  mahjong: {
    keywords: ["game", "play", "chinese", "kanji"],
    char: "\uD83C\uDC04",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  spades: {
    keywords: ["poker", "cards", "suits", "magic"],
    char: "\u2660\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clubs: {
    keywords: ["poker", "cards", "magic", "suits"],
    char: "\u2663\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  hearts: {
    keywords: ["poker", "cards", "magic", "suits"],
    char: "\u2665\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  diamonds: {
    keywords: ["poker", "cards", "magic", "suits"],
    char: "\u2666\uFE0F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  flower_playing_cards: {
    keywords: ["game", "sunset", "red"],
    char: "\uD83C\uDFB4",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  thought_balloon: {
    keywords: ["bubble", "cloud", "speech", "thinking", "dream"],
    char: "\uD83D\uDCAD",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  right_anger_bubble: {
    keywords: ["caption", "speech", "thinking", "mad"],
    char: "\uD83D\uDDEF",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  speech_balloon: {
    keywords: ["bubble", "words", "message", "talk", "chatting"],
    char: "\uD83D\uDCAC",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  left_speech_bubble: {
    keywords: ["words", "message", "talk", "chatting"],
    char: "\uD83D\uDDE8",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock1: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD50",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock2: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD51",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock3: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD52",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock4: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD53",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock5: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD54",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock6: {
    keywords: ["time", "late", "early", "schedule", "dawn", "dusk"],
    char: "\uD83D\uDD55",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock7: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD56",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock8: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD57",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock9: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD58",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock10: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD59",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock11: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD5A",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock12: {
    keywords: ["time", "noon", "midnight", "midday", "late", "early", "schedule"],
    char: "\uD83D\uDD5B",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock130: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD5C",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock230: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD5D",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock330: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD5E",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock430: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD5F",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock530: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD60",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock630: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD61",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock730: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD62",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock830: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD63",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock930: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD64",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock1030: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD65",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock1130: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD66",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  clock1230: {
    keywords: ["time", "late", "early", "schedule"],
    char: "\uD83D\uDD67",
    fitzpatrick_scale: false,
    category: "symbols"
  },
  afghanistan: {
    keywords: ["af", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  aland_islands: {
    keywords: ["\xC5land", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDFD",
    fitzpatrick_scale: false,
    category: "flags"
  },
  albania: {
    keywords: ["al", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  algeria: {
    keywords: ["dz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE9\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  american_samoa: {
    keywords: ["american", "ws", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  andorra: {
    keywords: ["ad", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  angola: {
    keywords: ["ao", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  anguilla: {
    keywords: ["ai", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  antarctica: {
    keywords: ["aq", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  antigua_barbuda: {
    keywords: ["antigua", "barbuda", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  argentina: {
    keywords: ["ar", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  armenia: {
    keywords: ["am", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  aruba: {
    keywords: ["aw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  australia: {
    keywords: ["au", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  austria: {
    keywords: ["at", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  azerbaijan: {
    keywords: ["az", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bahamas: {
    keywords: ["bs", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bahrain: {
    keywords: ["bh", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bangladesh: {
    keywords: ["bd", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  barbados: {
    keywords: ["bb", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDE7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  belarus: {
    keywords: ["by", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  belgium: {
    keywords: ["be", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  belize: {
    keywords: ["bz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  benin: {
    keywords: ["bj", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDEF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bermuda: {
    keywords: ["bm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bhutan: {
    keywords: ["bt", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bolivia: {
    keywords: ["bo", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  caribbean_netherlands: {
    keywords: ["bonaire", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bosnia_herzegovina: {
    keywords: ["bosnia", "herzegovina", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  botswana: {
    keywords: ["bw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  brazil: {
    keywords: ["br", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  british_indian_ocean_territory: {
    keywords: ["british", "indian", "ocean", "territory", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  british_virgin_islands: {
    keywords: ["british", "virgin", "islands", "bvi", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  brunei: {
    keywords: ["bn", "darussalam", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  bulgaria: {
    keywords: ["bg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  burkina_faso: {
    keywords: ["burkina", "faso", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  burundi: {
    keywords: ["bi", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cape_verde: {
    keywords: ["cabo", "verde", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDFB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cambodia: {
    keywords: ["kh", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cameroon: {
    keywords: ["cm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  canada: {
    keywords: ["ca", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  canary_islands: {
    keywords: ["canary", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cayman_islands: {
    keywords: ["cayman", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  central_african_republic: {
    keywords: ["central", "african", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  chad: {
    keywords: ["td", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  chile: {
    keywords: ["flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cn: {
    keywords: ["china", "chinese", "prc", "flag", "country", "nation", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  christmas_island: {
    keywords: ["christmas", "island", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDFD",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cocos_islands: {
    keywords: ["cocos", "keeling", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  colombia: {
    keywords: ["co", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  comoros: {
    keywords: ["km", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  congo_brazzaville: {
    keywords: ["congo", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  congo_kinshasa: {
    keywords: ["congo", "democratic", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cook_islands: {
    keywords: ["cook", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  costa_rica: {
    keywords: ["costa", "rica", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  croatia: {
    keywords: ["hr", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDED\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cuba: {
    keywords: ["cu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  curacao: {
    keywords: ["cura\xE7ao", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cyprus: {
    keywords: ["cy", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  czech_republic: {
    keywords: ["cz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  denmark: {
    keywords: ["dk", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE9\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  djibouti: {
    keywords: ["dj", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE9\uD83C\uDDEF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  dominica: {
    keywords: ["dm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE9\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  dominican_republic: {
    keywords: ["dominican", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE9\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  ecuador: {
    keywords: ["ec", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  egypt: {
    keywords: ["eg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  el_salvador: {
    keywords: ["el", "salvador", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDFB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  equatorial_guinea: {
    keywords: ["equatorial", "gn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  eritrea: {
    keywords: ["er", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  estonia: {
    keywords: ["ee", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  ethiopia: {
    keywords: ["et", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  eu: {
    keywords: ["european", "union", "flag", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  falkland_islands: {
    keywords: ["falkland", "islands", "malvinas", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEB\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  faroe_islands: {
    keywords: ["faroe", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEB\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  fiji: {
    keywords: ["fj", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEB\uD83C\uDDEF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  finland: {
    keywords: ["fi", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEB\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  fr: {
    keywords: ["banner", "flag", "nation", "france", "french", "country"],
    char: "\uD83C\uDDEB\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  french_guiana: {
    keywords: ["french", "guiana", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  french_polynesia: {
    keywords: ["french", "polynesia", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  french_southern_territories: {
    keywords: ["french", "southern", "territories", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  gabon: {
    keywords: ["ga", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  gambia: {
    keywords: ["gm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  georgia: {
    keywords: ["ge", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  de: {
    keywords: ["german", "nation", "flag", "country", "banner"],
    char: "\uD83C\uDDE9\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  ghana: {
    keywords: ["gh", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  gibraltar: {
    keywords: ["gi", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  greece: {
    keywords: ["gr", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  greenland: {
    keywords: ["gl", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  grenada: {
    keywords: ["gd", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guadeloupe: {
    keywords: ["gp", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF5",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guam: {
    keywords: ["gu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guatemala: {
    keywords: ["gt", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guernsey: {
    keywords: ["gg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guinea: {
    keywords: ["gn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guinea_bissau: {
    keywords: ["gw", "bissau", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  guyana: {
    keywords: ["gy", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  haiti: {
    keywords: ["ht", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDED\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  honduras: {
    keywords: ["hn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDED\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  hong_kong: {
    keywords: ["hong", "kong", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDED\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  hungary: {
    keywords: ["hu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDED\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  iceland: {
    keywords: ["is", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  india: {
    keywords: ["in", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  indonesia: {
    keywords: ["flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  iran: {
    keywords: ["iran,", "islamic", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  iraq: {
    keywords: ["iq", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  ireland: {
    keywords: ["ie", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  isle_of_man: {
    keywords: ["isle", "man", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  israel: {
    keywords: ["il", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  it: {
    keywords: ["italy", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEE\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  cote_divoire: {
    keywords: ["ivory", "coast", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  jamaica: {
    keywords: ["jm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEF\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  jp: {
    keywords: ["japanese", "nation", "flag", "country", "banner"],
    char: "\uD83C\uDDEF\uD83C\uDDF5",
    fitzpatrick_scale: false,
    category: "flags"
  },
  jersey: {
    keywords: ["je", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEF\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  jordan: {
    keywords: ["jo", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEF\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kazakhstan: {
    keywords: ["kz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kenya: {
    keywords: ["ke", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kiribati: {
    keywords: ["ki", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kosovo: {
    keywords: ["xk", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFD\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kuwait: {
    keywords: ["kw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kyrgyzstan: {
    keywords: ["kg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  laos: {
    keywords: ["lao", "democratic", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  latvia: {
    keywords: ["lv", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDFB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  lebanon: {
    keywords: ["lb", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDE7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  lesotho: {
    keywords: ["ls", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  liberia: {
    keywords: ["lr", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  libya: {
    keywords: ["ly", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  liechtenstein: {
    keywords: ["li", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  lithuania: {
    keywords: ["lt", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  luxembourg: {
    keywords: ["lu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  macau: {
    keywords: ["macao", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  macedonia: {
    keywords: ["macedonia,", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  madagascar: {
    keywords: ["mg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  malawi: {
    keywords: ["mw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  malaysia: {
    keywords: ["my", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  maldives: {
    keywords: ["mv", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDFB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mali: {
    keywords: ["ml", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  malta: {
    keywords: ["mt", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  marshall_islands: {
    keywords: ["marshall", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  martinique: {
    keywords: ["mq", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mauritania: {
    keywords: ["mr", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mauritius: {
    keywords: ["mu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mayotte: {
    keywords: ["yt", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFE\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mexico: {
    keywords: ["mx", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDFD",
    fitzpatrick_scale: false,
    category: "flags"
  },
  micronesia: {
    keywords: ["micronesia,", "federated", "states", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEB\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  moldova: {
    keywords: ["moldova,", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  monaco: {
    keywords: ["mc", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mongolia: {
    keywords: ["mn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  montenegro: {
    keywords: ["me", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  montserrat: {
    keywords: ["ms", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  morocco: {
    keywords: ["ma", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  mozambique: {
    keywords: ["mz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  myanmar: {
    keywords: ["mm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  namibia: {
    keywords: ["na", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  nauru: {
    keywords: ["nr", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  nepal: {
    keywords: ["np", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDF5",
    fitzpatrick_scale: false,
    category: "flags"
  },
  netherlands: {
    keywords: ["nl", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  new_caledonia: {
    keywords: ["new", "caledonia", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  new_zealand: {
    keywords: ["new", "zealand", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  nicaragua: {
    keywords: ["ni", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  niger: {
    keywords: ["ne", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  nigeria: {
    keywords: ["flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  niue: {
    keywords: ["nu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  norfolk_island: {
    keywords: ["norfolk", "island", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  northern_mariana_islands: {
    keywords: ["northern", "mariana", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF2\uD83C\uDDF5",
    fitzpatrick_scale: false,
    category: "flags"
  },
  north_korea: {
    keywords: ["north", "korea", "nation", "flag", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDF5",
    fitzpatrick_scale: false,
    category: "flags"
  },
  norway: {
    keywords: ["no", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF3\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  oman: {
    keywords: ["om_symbol", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF4\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  pakistan: {
    keywords: ["pk", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  palau: {
    keywords: ["pw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  palestinian_territories: {
    keywords: ["palestine", "palestinian", "territories", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  panama: {
    keywords: ["pa", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  papua_new_guinea: {
    keywords: ["papua", "new", "guinea", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  paraguay: {
    keywords: ["py", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  peru: {
    keywords: ["pe", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  philippines: {
    keywords: ["ph", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  pitcairn_islands: {
    keywords: ["pitcairn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  poland: {
    keywords: ["pl", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  portugal: {
    keywords: ["pt", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  puerto_rico: {
    keywords: ["puerto", "rico", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  qatar: {
    keywords: ["qa", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF6\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  reunion: {
    keywords: ["r\xE9union", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF7\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  romania: {
    keywords: ["ro", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF7\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  ru: {
    keywords: ["russian", "federation", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF7\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  rwanda: {
    keywords: ["rw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF7\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  st_barthelemy: {
    keywords: ["saint", "barth\xE9lemy", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE7\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  st_helena: {
    keywords: ["saint", "helena", "ascension", "tristan", "cunha", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  st_kitts_nevis: {
    keywords: ["saint", "kitts", "nevis", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  st_lucia: {
    keywords: ["saint", "lucia", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  st_pierre_miquelon: {
    keywords: ["saint", "pierre", "miquelon", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF5\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  st_vincent_grenadines: {
    keywords: ["saint", "vincent", "grenadines", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  samoa: {
    keywords: ["ws", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFC\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  san_marino: {
    keywords: ["san", "marino", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  sao_tome_principe: {
    keywords: ["sao", "tome", "principe", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  saudi_arabia: {
    keywords: ["flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  senegal: {
    keywords: ["sn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  serbia: {
    keywords: ["rs", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF7\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  seychelles: {
    keywords: ["sc", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  sierra_leone: {
    keywords: ["sierra", "leone", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  singapore: {
    keywords: ["sg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  sint_maarten: {
    keywords: ["sint", "maarten", "dutch", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDFD",
    fitzpatrick_scale: false,
    category: "flags"
  },
  slovakia: {
    keywords: ["sk", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  slovenia: {
    keywords: ["si", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  solomon_islands: {
    keywords: ["solomon", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDE7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  somalia: {
    keywords: ["so", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  south_africa: {
    keywords: ["south", "africa", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFF\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  south_georgia_south_sandwich_islands: {
    keywords: ["south", "georgia", "sandwich", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEC\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  kr: {
    keywords: ["south", "korea", "nation", "flag", "country", "banner"],
    char: "\uD83C\uDDF0\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  south_sudan: {
    keywords: ["south", "sd", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  es: {
    keywords: ["spain", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  sri_lanka: {
    keywords: ["sri", "lanka", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF1\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  sudan: {
    keywords: ["sd", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDE9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  suriname: {
    keywords: ["sr", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  swaziland: {
    keywords: ["sz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  sweden: {
    keywords: ["se", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  switzerland: {
    keywords: ["ch", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE8\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  syria: {
    keywords: ["syrian", "arab", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF8\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  taiwan: {
    keywords: ["tw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tajikistan: {
    keywords: ["tj", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDEF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tanzania: {
    keywords: ["tanzania,", "united", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  thailand: {
    keywords: ["th", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  timor_leste: {
    keywords: ["timor", "leste", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF1",
    fitzpatrick_scale: false,
    category: "flags"
  },
  togo: {
    keywords: ["tg", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tokelau: {
    keywords: ["tk", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF0",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tonga: {
    keywords: ["to", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF4",
    fitzpatrick_scale: false,
    category: "flags"
  },
  trinidad_tobago: {
    keywords: ["trinidad", "tobago", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF9",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tunisia: {
    keywords: ["tn", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tr: {
    keywords: ["turkey", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  turkmenistan: {
    keywords: ["flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  turks_caicos_islands: {
    keywords: ["turks", "caicos", "islands", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDE8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  tuvalu: {
    keywords: ["flag", "nation", "country", "banner"],
    char: "\uD83C\uDDF9\uD83C\uDDFB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  uganda: {
    keywords: ["ug", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFA\uD83C\uDDEC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  ukraine: {
    keywords: ["ua", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFA\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  united_arab_emirates: {
    keywords: ["united", "arab", "emirates", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDE6\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  uk: {
    keywords: ["united", "kingdom", "great", "britain", "northern", "ireland", "flag", "nation", "country", "banner", "british", "UK", "english", "england", "union jack"],
    char: "\uD83C\uDDEC\uD83C\uDDE7",
    fitzpatrick_scale: false,
    category: "flags"
  },
  england: {
    keywords: ["flag", "english"],
    char: "\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC65\uDB40\uDC6E\uDB40\uDC67\uDB40\uDC7F",
    fitzpatrick_scale: false,
    category: "flags"
  },
  scotland: {
    keywords: ["flag", "scottish"],
    char: "\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC73\uDB40\uDC63\uDB40\uDC74\uDB40\uDC7F",
    fitzpatrick_scale: false,
    category: "flags"
  },
  wales: {
    keywords: ["flag", "welsh"],
    char: "\uD83C\uDFF4\uDB40\uDC67\uDB40\uDC62\uDB40\uDC77\uDB40\uDC6C\uDB40\uDC73\uDB40\uDC7F",
    fitzpatrick_scale: false,
    category: "flags"
  },
  us: {
    keywords: ["united", "states", "america", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFA\uD83C\uDDF8",
    fitzpatrick_scale: false,
    category: "flags"
  },
  us_virgin_islands: {
    keywords: ["virgin", "islands", "us", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDEE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  uruguay: {
    keywords: ["uy", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFA\uD83C\uDDFE",
    fitzpatrick_scale: false,
    category: "flags"
  },
  uzbekistan: {
    keywords: ["uz", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFA\uD83C\uDDFF",
    fitzpatrick_scale: false,
    category: "flags"
  },
  vanuatu: {
    keywords: ["vu", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDFA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  vatican_city: {
    keywords: ["vatican", "city", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDE6",
    fitzpatrick_scale: false,
    category: "flags"
  },
  venezuela: {
    keywords: ["ve", "bolivarian", "republic", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  vietnam: {
    keywords: ["viet", "nam", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFB\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  wallis_futuna: {
    keywords: ["wallis", "futuna", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFC\uD83C\uDDEB",
    fitzpatrick_scale: false,
    category: "flags"
  },
  western_sahara: {
    keywords: ["western", "sahara", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDEA\uD83C\uDDED",
    fitzpatrick_scale: false,
    category: "flags"
  },
  yemen: {
    keywords: ["ye", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFE\uD83C\uDDEA",
    fitzpatrick_scale: false,
    category: "flags"
  },
  zambia: {
    keywords: ["zm", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFF\uD83C\uDDF2",
    fitzpatrick_scale: false,
    category: "flags"
  },
  zimbabwe: {
    keywords: ["zw", "flag", "nation", "country", "banner"],
    char: "\uD83C\uDDFF\uD83C\uDDFC",
    fitzpatrick_scale: false,
    category: "flags"
  },
  united_nations: {
    keywords: ["un", "flag", "banner"],
    char: "\uD83C\uDDFA\uD83C\uDDF3",
    fitzpatrick_scale: false,
    category: "flags"
  },
  pirate_flag: {
    keywords: ["skull", "crossbones", "flag", "banner"],
    char: "\uD83C\uDFF4\u200D\u2620\uFE0F",
    fitzpatrick_scale: false,
    category: "flags"
  }
});
export default {};